import * as React from "react";
import swal from "sweetalert";
import { useAuthProvider } from "../../core/authContext";
import {
  Allergens,
  useGetAllergensQuery,
  useDeleteAllergensMutation,
} from "../../generated/graphql";
const useAllergen = () => {
  const [editData, setEditData] = React.useState<Allergens | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleClickEditOpen = (data: Allergens | null) => {
    setEditData(data);
    setOpen(true);
  };
  const handleEditClose = () => {
    setEditData(null);
    setOpen(false);
  };

  const { getUser } = useAuthProvider();
  const property = getUser();

  // add allergens
  const {
    data: Allergens,
    refetch: refetchAllergen,
    loading: loadingAllergens,
  } = useGetAllergensQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

 const sortedAllergens= Allergens?.getAllergens?.sort((a, b) => ((a?.name)as any).localeCompare(b?.name)as any);
 
  const [deleteAllergens] = useDeleteAllergensMutation();

  // delete allergens
  const handleDelete = async (id: string) => {
    try {
      await deleteAllergens({
        variables: {
          id: id,
          propertyID: property?.id,
        },
      });
      swal({
        text: "Allergen Deleted Successfully",
        icon: "success",
      });
      refetchAllergen();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  return {
    Allergens:sortedAllergens,
    handleClickEditOpen,
    handleEditClose,
    editData,
    refetchAllergen,
    propertyId: property?.id,
    open,
    setOpen,
    loadingAllergens,
    handleDelete,
  };
};

export default useAllergen;
