import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type About = {
  __typename?: "About";
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type AboutInput = {
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type Allergens = {
  __typename?: "Allergens";
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type AllergensInput = {
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Cart = {
  __typename?: "Cart";
  dineInItem_id: Scalars["String"];
  dineInItem_name: Scalars["String"];
  dineInItem_price: Scalars["Float"];
  extras?: Maybe<Array<Maybe<ExtraOptions>>>;
  quantity: Scalars["Int"];
  selections?: Maybe<Array<Maybe<Selection>>>;
};

export type CartInput = {
  dineInItem_id: Scalars["String"];
  dineInItem_name: Scalars["String"];
  dineInItem_price: Scalars["Float"];
  extras?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  quantity: Scalars["Int"];
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
};

export enum Days {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export enum Diet_Type {
  NonVegan = "NonVegan",
  None = "None",
  Vegan = "Vegan",
}

export type DineInCategories = {
  __typename?: "DineInCategories";
  desc?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type DineInCategoriesInput = {
  desc?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type DineInItem = {
  __typename?: "DineInItem";
  allergens?: Maybe<Array<Maybe<Scalars["String"]>>>;
  availabletime?: Maybe<Scalars["String"]>;
  definitionsequence?: Maybe<Scalars["Int"]>;
  desc: Scalars["String"];
  extraOptions?: Maybe<Array<Maybe<ExtraOptions>>>;
  id: Scalars["ID"];
  img: Scalars["String"];
  isVegan?: Maybe<Diet_Type>;
  is_Activated?: Maybe<Scalars["Boolean"]>;
  is_recommended?: Maybe<Scalars["Boolean"]>;
  menu_item_id?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  section?: Maybe<Array<Maybe<Scalars["String"]>>>;
  selections?: Maybe<Array<Maybe<Selection>>>;
  sequence?: Maybe<Scalars["Int"]>;
  spice_level?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type DineInItemInput = {
  allergens?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  availabletime?: InputMaybe<Scalars["String"]>;
  definitionsequence?: InputMaybe<Scalars["Int"]>;
  desc: Scalars["String"];
  extraOptions?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  isVegan?: InputMaybe<Diet_Type>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  is_recommended?: InputMaybe<Scalars["Boolean"]>;
  menu_item_id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  section?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
  sequence?: InputMaybe<Scalars["Int"]>;
  spice_level?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type DineInSection = {
  __typename?: "DineInSection";
  default_category?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  message?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["String"]>;
  time_based?: Maybe<Scalars["Boolean"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type DineInSectionInput = {
  default_category?: InputMaybe<Scalars["String"]>;
  desc?: InputMaybe<Scalars["String"]>;
  end_time?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  message?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["String"]>;
  time_based?: InputMaybe<Scalars["Boolean"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Dining = {
  __typename?: "Dining";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  service_id?: Maybe<Scalars["Int"]>;
};

export type DiningInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  service_id?: InputMaybe<Scalars["Int"]>;
};

export type DirectionsAndContact = {
  __typename?: "DirectionsAndContact";
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type DirectionsAndContactInput = {
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type ExtraOptions = {
  __typename?: "ExtraOptions";
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type ExtraOptionsInput = {
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Feedback = {
  __typename?: "Feedback";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type FeedbackInput = {
  feedback: Scalars["String"];
  feedback_type_id: Scalars["Int"];
  property_id: Scalars["String"];
  room_id: Scalars["String"];
};

export type HotelService = {
  __typename?: "HotelService";
  desc?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  sub_services?: Maybe<Array<Maybe<SubService>>>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type HotelServiceInput = {
  desc?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Is_Activated = {
  __typename?: "Is_Activated";
  about?: Maybe<Scalars["Boolean"]>;
  dining?: Maybe<Scalars["Boolean"]>;
  directory?: Maybe<Scalars["Boolean"]>;
  hotel_rooms?: Maybe<Scalars["Boolean"]>;
  hotel_services?: Maybe<Scalars["Boolean"]>;
};

export type Is_ActivatedInput = {
  about?: InputMaybe<Scalars["Boolean"]>;
  dining?: InputMaybe<Scalars["Boolean"]>;
  directory?: InputMaybe<Scalars["Boolean"]>;
  hotel_rooms?: InputMaybe<Scalars["Boolean"]>;
  hotel_services?: InputMaybe<Scalars["Boolean"]>;
};

export type Layout = {
  __typename?: "Layout";
  cardName: Scalars["String"];
  sequence: Scalars["Int"];
};

export type LayoutInput = {
  cardName: Scalars["String"];
  sequence: Scalars["Int"];
};

export type MetaData = {
  __typename?: "MetaData";
  about: About;
  dining?: Maybe<Array<Maybe<Dining>>>;
  dining_image?: Maybe<Scalars["String"]>;
  dir_con: DirectionsAndContact;
  directory_description?: Maybe<Scalars["String"]>;
  feedback_desc?: Maybe<Scalars["String"]>;
  hotel_name: Scalars["String"];
  hotel_rooms?: Maybe<Array<Room>>;
  hotel_services?: Maybe<Array<HotelService>>;
  hotel_services_image?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  is_Activated?: Maybe<Is_Activated>;
  order_service_id?: Maybe<Scalars["Int"]>;
  promotion_desc?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  rooms_image?: Maybe<Scalars["String"]>;
};

export type MetaDataInput = {
  about: AboutInput;
  dining_image?: InputMaybe<Scalars["String"]>;
  dir_con: DirectionsAndContactInput;
  directory_description?: InputMaybe<Scalars["String"]>;
  feedback_desc?: InputMaybe<Scalars["String"]>;
  hotel_name: Scalars["String"];
  hotel_services_image?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Is_ActivatedInput>;
  order_service_id?: InputMaybe<Scalars["Int"]>;
  promotion_desc?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  rooms_image?: InputMaybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  cancelOrder?: Maybe<Scalars["String"]>;
  changeOrderStatus?: Maybe<Scalars["String"]>;
  createFeedback?: Maybe<Response>;
  createSimphonyWithComboMeals?: Maybe<StatusResponse>;
  createSimphonyWithInvalidItem?: Maybe<StatusResponse>;
  createSimphonyWithInvalidProperty?: Maybe<StatusResponse>;
  deleteAllergens?: Maybe<Scalars["String"]>;
  deleteDineInCategory?: Maybe<Scalars["String"]>;
  deleteDineInSection?: Maybe<Scalars["String"]>;
  deleteDining?: Maybe<Scalars["String"]>;
  deleteEdible?: Maybe<Scalars["String"]>;
  deleteHotelService?: Maybe<Scalars["String"]>;
  deleteOrder?: Maybe<Scalars["String"]>;
  deletePromotion?: Maybe<Scalars["String"]>;
  deleteProperty?: Maybe<Property>;
  deleteRoom?: Maybe<Scalars["String"]>;
  deleteUser?: Maybe<Scalars["String"]>;
  requestService?: Maybe<Response>;
  requestServices?: Maybe<Response>;
  syncSimphonyPOSMenuItems?: Maybe<StatusResponse>;
  updateEdibleStatus?: Maybe<DineInItem>;
  updatePromotionStatus?: Maybe<Promotion>;
  upsertAllergens?: Maybe<Allergens>;
  upsertApiKey?: Maybe<Scalars["String"]>;
  upsertDineInCategory?: Maybe<DineInCategories>;
  upsertDineInSection?: Maybe<DineInSection>;
  upsertDining?: Maybe<Scalars["String"]>;
  upsertEdible?: Maybe<DineInItem>;
  upsertHotelService?: Maybe<Scalars["String"]>;
  upsertMetaData?: Maybe<Scalars["String"]>;
  upsertOrder?: Maybe<OrderResponse>;
  upsertPromotion?: Maybe<Promotion>;
  upsertProperty?: Maybe<Property>;
  upsertRoom?: Maybe<Room>;
  upsertUser?: Maybe<User>;
  verifyGuest?: Maybe<Response>;
};

export type MutationCancelOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationChangeOrderStatusArgs = {
  id: Scalars["String"];
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationCreateSimphonyWithComboMealsArgs = {
  propertyID: Scalars["String"];
};

export type MutationCreateSimphonyWithInvalidItemArgs = {
  propertyID: Scalars["String"];
};

export type MutationCreateSimphonyWithInvalidPropertyArgs = {
  propertyID: Scalars["String"];
};

export type MutationDeleteAllergensArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDineInCategoryArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDineInSectionArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDiningArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteEdibleArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteHotelServiceArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePromotionArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePropertyArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteRoomArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationRequestServiceArgs = {
  serviceInput: ServiceInput;
};

export type MutationRequestServicesArgs = {
  servicesInput?: InputMaybe<Array<ServiceInput>>;
};

export type MutationSyncSimphonyPosMenuItemsArgs = {
  propertyID: Scalars["String"];
};

export type MutationUpdateEdibleStatusArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpdatePromotionStatusArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpsertAllergensArgs = {
  allergensInput?: InputMaybe<AllergensInput>;
};

export type MutationUpsertApiKeyArgs = {
  apiKey: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationUpsertDineInCategoryArgs = {
  dineInCategoriesInput?: InputMaybe<DineInCategoriesInput>;
};

export type MutationUpsertDineInSectionArgs = {
  dineInSectionInput?: InputMaybe<DineInSectionInput>;
};

export type MutationUpsertDiningArgs = {
  diningInput: DiningInput;
};

export type MutationUpsertEdibleArgs = {
  dineInItemInput: DineInItemInput;
};

export type MutationUpsertHotelServiceArgs = {
  hotelServiceInput: HotelServiceInput;
};

export type MutationUpsertMetaDataArgs = {
  metaInput: MetaDataInput;
};

export type MutationUpsertOrderArgs = {
  orderInput: OrderInput;
};

export type MutationUpsertPromotionArgs = {
  promotionInput: PromotionInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertRoomArgs = {
  roomInput: RoomInput;
};

export type MutationUpsertUserArgs = {
  userInput: UserInput;
};

export type MutationVerifyGuestArgs = {
  guestName: Scalars["String"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  cart_items?: Maybe<Array<Cart>>;
  comment?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  id: Scalars["ID"];
  order_id: Scalars["String"];
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: Maybe<Status>;
  total: Scalars["Float"];
  updated_at?: Maybe<Scalars["String"]>;
};

export type OrderInput = {
  cart_items?: InputMaybe<Array<CartInput>>;
  comment?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["String"]>;
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  order_id?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: InputMaybe<Status>;
  total: Scalars["Float"];
};

export type OrderResponse = {
  __typename?: "OrderResponse";
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  order?: Maybe<Order>;
};

export type Promotion = {
  __typename?: "Promotion";
  banner_image: Scalars["String"];
  description: Scalars["String"];
  end_date: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  start_date: Scalars["String"];
};

export type PromotionInput = {
  banner_image: Scalars["String"];
  description: Scalars["String"];
  end_date: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  start_date: Scalars["String"];
};

export type Property = {
  __typename?: "Property";
  background_color?: Maybe<Scalars["String"]>;
  by_dining?: Maybe<Scalars["String"]>;
  chat_api_url: Scalars["String"];
  currency?: Maybe<Scalars["String"]>;
  direct_simphony_pos_check?: Maybe<Scalars["Boolean"]>;
  domain?: Maybe<Scalars["String"]>;
  font?: Maybe<Scalars["String"]>;
  has_simphony_pos?: Maybe<Scalars["Boolean"]>;
  hotlync_api_url?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  img: Scalars["String"];
  layout?: Maybe<Array<Maybe<Layout>>>;
  name: Scalars["String"];
  pos_loc_ref?: Maybe<Scalars["String"]>;
  pos_rvc_ref?: Maybe<Scalars["String"]>;
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  secondary_text_color: Scalars["String"];
  sender_email?: Maybe<Scalars["String"]>;
  show_main_category?: Maybe<Scalars["Boolean"]>;
  show_talk_to_us?: Maybe<Scalars["Boolean"]>;
  simphony_pos_url?: Maybe<Scalars["String"]>;
  tile_color?: Maybe<Scalars["String"]>;
};

export type PropertyInput = {
  background_color: Scalars["String"];
  by_dining?: InputMaybe<Scalars["String"]>;
  chat_api_url: Scalars["String"];
  currency: Scalars["String"];
  direct_simphony_pos_check?: InputMaybe<Scalars["Boolean"]>;
  domain: Scalars["String"];
  font?: InputMaybe<Scalars["String"]>;
  has_simphony_pos?: InputMaybe<Scalars["Boolean"]>;
  hotlync_api_url: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  layout?: InputMaybe<Array<InputMaybe<LayoutInput>>>;
  name: Scalars["String"];
  pos_loc_ref?: InputMaybe<Scalars["String"]>;
  pos_rvc_ref?: InputMaybe<Scalars["String"]>;
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  secondary_text_color: Scalars["String"];
  sender_email?: InputMaybe<Scalars["String"]>;
  show_main_category?: InputMaybe<Scalars["Boolean"]>;
  show_talk_to_us?: InputMaybe<Scalars["Boolean"]>;
  simphony_pos_url?: InputMaybe<Scalars["String"]>;
  tile_color?: InputMaybe<Scalars["String"]>;
};

export type Query = {
  __typename?: "Query";
  getAdminPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getAllDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getAllergens?: Maybe<Array<Maybe<Allergens>>>;
  getDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getFeedbackTypeList?: Maybe<Array<Maybe<Feedback>>>;
  getGuest?: Maybe<Response>;
  getGuestEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getGuestPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getHotelService?: Maybe<HotelService>;
  getMeta?: Maybe<MetaData>;
  getOrdersByStatus?: Maybe<Array<Maybe<Order>>>;
  getPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getProperty?: Maybe<Property>;
  getPropertyByDomain?: Maybe<Property>;
  getQuickServices?: Maybe<QuickServices>;
  getServices?: Maybe<ServiceByDeptmt>;
  getTaskHistory?: Maybe<Array<Maybe<TaskHistory>>>;
  getUploadSignedURL?: Maybe<SignedUrl>;
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  simphonyDiscounts?: Maybe<StatusResponse>;
  simphonyServiceCharges?: Maybe<StatusResponse>;
};

export type QueryGetAdminPromotionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllDineInSectionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllergensArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetDineInCategoriesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetDineInSectionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetFeedbackTypeListArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestArgs = {
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetGuestEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestOrdersArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
};

export type QueryGetGuestPromotionsArgs = {
  currDate: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetHotelServiceArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetMetaArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetOrdersByStatusArgs = {
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type QueryGetPromotionsArgs = {
  currDate: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetPropertyArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyByDomainArgs = {
  domain: Scalars["String"];
};

export type QueryGetQuickServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetTaskHistoryArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetUploadSignedUrlArgs = {
  extension: Scalars["String"];
  fileType: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetUserArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QuerySimphonyDiscountsArgs = {
  propertyID: Scalars["String"];
};

export type QuerySimphonyServiceChargesArgs = {
  propertyID: Scalars["String"];
};

export type QuickService = {
  __typename?: "QuickService";
  department: Scalars["String"];
  id: Scalars["String"];
  qty_by_guest: Scalars["Int"];
  task: Scalars["String"];
};

export type QuickServices = {
  __typename?: "QuickServices";
  code: Scalars["Int"];
  quicktasklist?: Maybe<Array<Maybe<QuickService>>>;
};

export type Response = {
  __typename?: "Response";
  code: Scalars["String"];
  guest_id?: Maybe<Scalars["Int"]>;
  guest_name?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  property_id?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
};

export type Room = {
  __typename?: "Room";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
};

export type RoomInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  size?: InputMaybe<Scalars["Int"]>;
};

export type Selection = {
  __typename?: "Selection";
  id: Scalars["String"];
  limit?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  selection_items: Array<Maybe<SelectionItem>>;
};

export type SelectionInput = {
  id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  selection_items: Array<InputMaybe<SelectionItemInput>>;
};

export type SelectionItem = {
  __typename?: "SelectionItem";
  id: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type SelectionItemInput = {
  id: Scalars["String"];
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Service = {
  __typename?: "Service";
  id: Scalars["Int"];
  task: Scalars["String"];
};

export type ServiceByDeptmt = {
  __typename?: "ServiceByDeptmt";
  code: Scalars["Int"];
  tasklist?: Maybe<Scalars["AWSJSON"]>;
};

export type ServiceInput = {
  notes?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  quantity: Scalars["Int"];
  room_id: Scalars["String"];
  task_id: Scalars["Int"];
};

export type SignedUrl = {
  __typename?: "SignedURL";
  presigned_upload_url?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum Status {
  Cancelled = "CANCELLED",
  Delivered = "DELIVERED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type StatusResponse = {
  __typename?: "StatusResponse";
  data?: Maybe<Scalars["AWSJSON"]>;
  message?: Maybe<Scalars["String"]>;
  status: Scalars["Int"];
};

export type SubService = {
  __typename?: "SubService";
  booking_url?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  service_id?: Maybe<Scalars["Int"]>;
};

export type SubServiceInput = {
  booking_url?: InputMaybe<Scalars["String"]>;
  desc?: InputMaybe<Scalars["String"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  service_id?: InputMaybe<Scalars["Int"]>;
};

export type TaskHistory = {
  __typename?: "TaskHistory";
  created_time?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  task_list?: Maybe<Scalars["Int"]>;
  task_name?: Maybe<Scalars["String"]>;
};

export enum Types {
  Alcohol = "ALCOHOL",
  Breakfast = "BREAKFAST",
  Desserts = "DESSERTS",
  Drinks = "DRINKS",
  HotDrinks = "HOT_DRINKS",
  Mains = "MAINS",
  Salads = "SALADS",
  Soups = "SOUPS",
  Starters = "STARTERS",
}

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  is_authorized?: Maybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  profile_url?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  reports_to?: Maybe<Scalars["String"]>;
  role?: Maybe<UserRole>;
  shift_end?: Maybe<Scalars["String"]>;
  shift_start?: Maybe<Scalars["String"]>;
  working_days?: Maybe<Array<Maybe<Days>>>;
};

export type UserInput = {
  email: Scalars["String"];
  first_name: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  is_authorized?: InputMaybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  role?: InputMaybe<UserRole>;
  shift_end?: InputMaybe<Scalars["String"]>;
  shift_start?: InputMaybe<Scalars["String"]>;
  working_days?: InputMaybe<Array<InputMaybe<Days>>>;
};

export enum UserRole {
  Admin = "ADMIN",
  Restaurant = "RESTAURANT",
}

export type GetAllergensQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetAllergensQuery = {
  __typename?: "Query";
  getAllergens?: Array<{
    __typename?: "Allergens";
    name: string;
    id: string;
    icon?: string | null;
    property_id: string;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type AddAllergensMutationVariables = Exact<{
  allergensInput: AllergensInput;
}>;

export type AddAllergensMutation = {
  __typename?: "Mutation";
  upsertAllergens?: { __typename?: "Allergens"; id: string } | null;
};

export type DeleteAllergensMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteAllergensMutation = {
  __typename?: "Mutation";
  deleteAllergens?: string | null;
};

export type GetDineInCategoriesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetDineInCategoriesQuery = {
  __typename?: "Query";
  getDineInCategories?: Array<{
    __typename?: "DineInCategories";
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    sequence?: number | null;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type AddDineInCategoriesMutationVariables = Exact<{
  dineInCategoriesInput: DineInCategoriesInput;
}>;

export type AddDineInCategoriesMutation = {
  __typename?: "Mutation";
  upsertDineInCategory?: { __typename?: "DineInCategories"; id: string } | null;
};

export type DeleteDineInCategoriesMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteDineInCategoriesMutation = {
  __typename?: "Mutation";
  deleteDineInCategory?: string | null;
};

export type GetDineInSectionsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetDineInSectionsQuery = {
  __typename?: "Query";
  getAllDineInSections?: Array<{
    __typename?: "DineInSection";
    name: string;
    id: string;
    icon: string;
    property_id: string;
    desc?: string | null;
    start_time?: string | null;
    end_time?: string | null;
    sequence?: number | null;
    default_category?: string | null;
    time_based?: boolean | null;
    message?: string | null;
    uploaded_icon?: string | null;
  } | null> | null;
};

export type AddDineInSectionsMutationVariables = Exact<{
  dineInSectionInput: DineInSectionInput;
}>;

export type AddDineInSectionsMutation = {
  __typename?: "Mutation";
  upsertDineInSection?: { __typename?: "DineInSection"; id: string } | null;
};

export type DeleteDineInSectionsMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteDineInSectionsMutation = {
  __typename?: "Mutation";
  deleteDineInSection?: string | null;
};

export type GetEdibleQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetEdibleQuery = {
  __typename?: "Query";
  getEdibles?: Array<{
    __typename?: "DineInItem";
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan?: Diet_Type | null;
    availabletime?: string | null;
    allergens?: Array<string | null> | null;
    is_Activated?: boolean | null;
    section?: Array<string | null> | null;
    is_recommended?: boolean | null;
    spice_level?: string | null;
    sequence?: number | null;
    extraOptions?: Array<{
      __typename?: "ExtraOptions";
      name: string;
      price?: number | null;
    } | null> | null;
    selections?: Array<{
      __typename?: "Selection";
      id: string;
      name: string;
      limit?: number | null;
      selection_items: Array<{
        __typename?: "SelectionItem";
        id: string;
        name: string;
        price?: number | null;
      } | null>;
    } | null> | null;
  } | null> | null;
};

export type AddEdibleMutationVariables = Exact<{
  dineInItemInput: DineInItemInput;
}>;

export type AddEdibleMutation = {
  __typename?: "Mutation";
  upsertEdible?: { __typename?: "DineInItem"; id: string } | null;
};

export type DeleteEdibleMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteEdibleMutation = {
  __typename?: "Mutation";
  deleteEdible?: string | null;
};

export type UpdateEdibleStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UpdateEdibleStatusMutation = {
  __typename?: "Mutation";
  updateEdibleStatus?: {
    __typename?: "DineInItem";
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan?: Diet_Type | null;
    availabletime?: string | null;
    allergens?: Array<string | null> | null;
    is_Activated?: boolean | null;
    section?: Array<string | null> | null;
    extraOptions?: Array<{
      __typename?: "ExtraOptions";
      name: string;
      price?: number | null;
    } | null> | null;
  } | null;
};

export type GetHotelDineInQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetHotelDineInQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    dining?: Array<{
      __typename?: "Dining";
      desc: string;
      id: string;
      img: string;
      name: string;
      property_id: string;
    } | null> | null;
  } | null;
};

export type AddHotelDineInMutationVariables = Exact<{
  diningInput: DiningInput;
}>;

export type AddHotelDineInMutation = {
  __typename?: "Mutation";
  upsertDining?: string | null;
};

export type DeleteDiningMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteDiningMutation = {
  __typename?: "Mutation";
  deleteDining?: string | null;
};

export type GetHotelInformationQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetHotelInformationQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    hotel_name: string;
    property_id: string;
    feedback_desc?: string | null;
    promotion_desc?: string | null;
    dining_image?: string | null;
    hotel_services_image?: string | null;
    rooms_image?: string | null;
    directory_description?: string | null;
    dir_con: {
      __typename?: "DirectionsAndContact";
      address: string;
      email: string;
      img: string;
      phone: string;
    };
    about: { __typename?: "About"; desc: string; img: string };
    is_Activated?: {
      __typename?: "Is_Activated";
      about?: boolean | null;
      dining?: boolean | null;
      directory?: boolean | null;
      hotel_rooms?: boolean | null;
      hotel_services?: boolean | null;
    } | null;
  } | null;
};

export type UpdatePropertyMutationVariables = Exact<{
  propertyInput: PropertyInput;
}>;

export type UpdatePropertyMutation = {
  __typename?: "Mutation";
  upsertProperty?: { __typename?: "Property"; id: string } | null;
};

export type UpdateMetaDataMutationVariables = Exact<{
  metaInput: MetaDataInput;
}>;

export type UpdateMetaDataMutation = {
  __typename?: "Mutation";
  upsertMetaData?: string | null;
};

export type UpdateApiKeyMutationVariables = Exact<{
  apiKey: Scalars["String"];
  propertyID: Scalars["String"];
}>;

export type UpdateApiKeyMutation = {
  __typename?: "Mutation";
  upsertApiKey?: string | null;
};

export type GetHotelServicesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetHotelServicesQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    hotel_services?: Array<{
      __typename?: "HotelService";
      desc?: string | null;
      id: string;
      img?: string | null;
      name: string;
      property_id: string;
      icon?: string | null;
      uploaded_icon?: string | null;
      sequence?: number | null;
    }> | null;
  } | null;
};

export type GetHotelServiceQueryVariables = Exact<{
  propertyID: Scalars["String"];
  id: Scalars["String"];
}>;

export type GetHotelServiceQuery = {
  __typename?: "Query";
  getHotelService?: {
    __typename?: "HotelService";
    id: string;
    img?: string | null;
    name: string;
    desc?: string | null;
    property_id: string;
    icon?: string | null;
    sub_services?: Array<{
      __typename?: "SubService";
      name: string;
      img?: string | null;
      desc?: string | null;
      booking_url?: string | null;
      service_id?: number | null;
    } | null> | null;
  } | null;
};

export type AddServicesMutationVariables = Exact<{
  hotelServicesInput: HotelServiceInput;
}>;

export type AddServicesMutation = {
  __typename?: "Mutation";
  upsertHotelService?: string | null;
};

export type DeleteHotelServiceMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeleteHotelServiceMutation = {
  __typename?: "Mutation";
  deleteHotelService?: string | null;
};

export type GetPromotionQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetPromotionQuery = {
  __typename?: "Query";
  getAdminPromotions?: Array<{
    __typename?: "Promotion";
    banner_image: string;
    description: string;
    end_date: string;
    id: string;
    name: string;
    property_id: string;
    start_date: string;
    is_Activated?: boolean | null;
  } | null> | null;
};

export type AddPromotionMutationVariables = Exact<{
  promotionInput: PromotionInput;
}>;

export type AddPromotionMutation = {
  __typename?: "Mutation";
  upsertPromotion?: { __typename?: "Promotion"; id: string } | null;
};

export type DeletePromotionsMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type DeletePromotionsMutation = {
  __typename?: "Mutation";
  deletePromotion?: string | null;
};

export type UpdatePromotionStatusMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
}>;

export type UpdatePromotionStatusMutation = {
  __typename?: "Mutation";
  updatePromotionStatus?: {
    __typename?: "Promotion";
    banner_image: string;
    description: string;
    end_date: string;
    id: string;
    name: string;
    property_id: string;
    start_date: string;
    is_Activated?: boolean | null;
  } | null;
};

export type GetPropertyDetailsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetPropertyDetailsQuery = {
  __typename?: "Query";
  getProperty?: {
    __typename?: "Property";
    chat_api_url: string;
    domain?: string | null;
    hotlync_api_url?: string | null;
    id: string;
    img: string;
    name: string;
    primary_color: string;
    primary_light: string;
    primary_text_color: string;
    secondary_text_color: string;
    currency?: string | null;
    background_color?: string | null;
    font?: string | null;
    tile_color?: string | null;
    by_dining?: string | null;
    show_talk_to_us?: boolean | null;
    show_main_category?: boolean | null;
    has_simphony_pos?: boolean | null;
    simphony_pos_url?: string | null;
    direct_simphony_pos_check?: boolean | null;
    pos_loc_ref?: string | null;
    pos_rvc_ref?: string | null;
  } | null;
};

export type GetRoomsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetRoomsQuery = {
  __typename?: "Query";
  getMeta?: {
    __typename?: "MetaData";
    id: string;
    hotel_rooms?: Array<{
      __typename?: "Room";
      desc: string;
      id: string;
      img: string;
      property_id: string;
      room_type: string;
    }> | null;
  } | null;
};

export type AddRoomMutationVariables = Exact<{
  roomInput: RoomInput;
}>;

export type AddRoomMutation = {
  __typename?: "Mutation";
  upsertRoom?: { __typename?: "Room"; id: string } | null;
};

export type DeleteRoomMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteRoomMutation = {
  __typename?: "Mutation";
  deleteRoom?: string | null;
};

export type GetUploadSignedUrlQueryVariables = Exact<{
  fileType: Scalars["String"];
  extension: Scalars["String"];
  propertyID: Scalars["String"];
}>;

export type GetUploadSignedUrlQuery = {
  __typename?: "Query";
  getUploadSignedURL?: {
    __typename?: "SignedURL";
    url?: string | null;
    presigned_upload_url?: string | null;
  } | null;
};

export type SyncSimphonyPosMutationVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SyncSimphonyPosMutation = {
  __typename?: "Mutation";
  syncSimphonyPOSMenuItems?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type CreateSimphonyWithComboMealsMutationVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type CreateSimphonyWithComboMealsMutation = {
  __typename?: "Mutation";
  createSimphonyWithComboMeals?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type SimphonyDiscountsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SimphonyDiscountsQuery = {
  __typename?: "Query";
  simphonyDiscounts?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export type SimphonyServiceChargesQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type SimphonyServiceChargesQuery = {
  __typename?: "Query";
  simphonyServiceCharges?: {
    __typename?: "StatusResponse";
    status: number;
    message?: string | null;
    data?: any | null;
  } | null;
};

export const GetAllergensDocument = gql`
  query getAllergens($propertyID: String!) {
    getAllergens(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      uploaded_icon
    }
  }
`;

/**
 * __useGetAllergensQuery__
 *
 * To run a query within a React component, call `useGetAllergensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllergensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllergensQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetAllergensQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >(GetAllergensDocument, options);
}
export function useGetAllergensLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetAllergensQuery,
    GetAllergensQueryVariables
  >(GetAllergensDocument, options);
}
export type GetAllergensQueryHookResult = ReturnType<
  typeof useGetAllergensQuery
>;
export type GetAllergensLazyQueryHookResult = ReturnType<
  typeof useGetAllergensLazyQuery
>;
export type GetAllergensQueryResult = ApolloReactCommon.QueryResult<
  GetAllergensQuery,
  GetAllergensQueryVariables
>;
export const AddAllergensDocument = gql`
  mutation addAllergens($allergensInput: AllergensInput!) {
    upsertAllergens(allergensInput: $allergensInput) {
      id
    }
  }
`;
export type AddAllergensMutationFn = ApolloReactCommon.MutationFunction<
  AddAllergensMutation,
  AddAllergensMutationVariables
>;

/**
 * __useAddAllergensMutation__
 *
 * To run a mutation, you first call `useAddAllergensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllergensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllergensMutation, { data, loading, error }] = useAddAllergensMutation({
 *   variables: {
 *      allergensInput: // value for 'allergensInput'
 *   },
 * });
 */
export function useAddAllergensMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddAllergensMutation,
    AddAllergensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddAllergensMutation,
    AddAllergensMutationVariables
  >(AddAllergensDocument, options);
}
export type AddAllergensMutationHookResult = ReturnType<
  typeof useAddAllergensMutation
>;
export type AddAllergensMutationResult =
  ApolloReactCommon.MutationResult<AddAllergensMutation>;
export type AddAllergensMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddAllergensMutation,
  AddAllergensMutationVariables
>;
export const DeleteAllergensDocument = gql`
  mutation deleteAllergens($id: ID!, $propertyID: String!) {
    deleteAllergens(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteAllergensMutationFn = ApolloReactCommon.MutationFunction<
  DeleteAllergensMutation,
  DeleteAllergensMutationVariables
>;

/**
 * __useDeleteAllergensMutation__
 *
 * To run a mutation, you first call `useDeleteAllergensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllergensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllergensMutation, { data, loading, error }] = useDeleteAllergensMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteAllergensMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteAllergensMutation,
    DeleteAllergensMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteAllergensMutation,
    DeleteAllergensMutationVariables
  >(DeleteAllergensDocument, options);
}
export type DeleteAllergensMutationHookResult = ReturnType<
  typeof useDeleteAllergensMutation
>;
export type DeleteAllergensMutationResult =
  ApolloReactCommon.MutationResult<DeleteAllergensMutation>;
export type DeleteAllergensMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteAllergensMutation,
    DeleteAllergensMutationVariables
  >;
export const GetDineInCategoriesDocument = gql`
  query getDineInCategories($propertyID: String!) {
    getDineInCategories(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      sequence
      uploaded_icon
    }
  }
`;

/**
 * __useGetDineInCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDineInCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDineInCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDineInCategoriesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDineInCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >(GetDineInCategoriesDocument, options);
}
export function useGetDineInCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDineInCategoriesQuery,
    GetDineInCategoriesQueryVariables
  >(GetDineInCategoriesDocument, options);
}
export type GetDineInCategoriesQueryHookResult = ReturnType<
  typeof useGetDineInCategoriesQuery
>;
export type GetDineInCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetDineInCategoriesLazyQuery
>;
export type GetDineInCategoriesQueryResult = ApolloReactCommon.QueryResult<
  GetDineInCategoriesQuery,
  GetDineInCategoriesQueryVariables
>;
export const AddDineInCategoriesDocument = gql`
  mutation addDineInCategories($dineInCategoriesInput: DineInCategoriesInput!) {
    upsertDineInCategory(dineInCategoriesInput: $dineInCategoriesInput) {
      id
    }
  }
`;
export type AddDineInCategoriesMutationFn = ApolloReactCommon.MutationFunction<
  AddDineInCategoriesMutation,
  AddDineInCategoriesMutationVariables
>;

/**
 * __useAddDineInCategoriesMutation__
 *
 * To run a mutation, you first call `useAddDineInCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDineInCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDineInCategoriesMutation, { data, loading, error }] = useAddDineInCategoriesMutation({
 *   variables: {
 *      dineInCategoriesInput: // value for 'dineInCategoriesInput'
 *   },
 * });
 */
export function useAddDineInCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddDineInCategoriesMutation,
    AddDineInCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddDineInCategoriesMutation,
    AddDineInCategoriesMutationVariables
  >(AddDineInCategoriesDocument, options);
}
export type AddDineInCategoriesMutationHookResult = ReturnType<
  typeof useAddDineInCategoriesMutation
>;
export type AddDineInCategoriesMutationResult =
  ApolloReactCommon.MutationResult<AddDineInCategoriesMutation>;
export type AddDineInCategoriesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddDineInCategoriesMutation,
    AddDineInCategoriesMutationVariables
  >;
export const DeleteDineInCategoriesDocument = gql`
  mutation deleteDineInCategories($id: ID!, $propertyID: String!) {
    deleteDineInCategory(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteDineInCategoriesMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >;

/**
 * __useDeleteDineInCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteDineInCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDineInCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDineInCategoriesMutation, { data, loading, error }] = useDeleteDineInCategoriesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteDineInCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >(DeleteDineInCategoriesDocument, options);
}
export type DeleteDineInCategoriesMutationHookResult = ReturnType<
  typeof useDeleteDineInCategoriesMutation
>;
export type DeleteDineInCategoriesMutationResult =
  ApolloReactCommon.MutationResult<DeleteDineInCategoriesMutation>;
export type DeleteDineInCategoriesMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteDineInCategoriesMutation,
    DeleteDineInCategoriesMutationVariables
  >;
export const GetDineInSectionsDocument = gql`
  query getDineInSections($propertyID: String!) {
    getAllDineInSections(propertyID: $propertyID) {
      name
      id
      icon
      property_id
      desc
      start_time
      end_time
      sequence
      default_category
      time_based
      message
      uploaded_icon
    }
  }
`;

/**
 * __useGetDineInSectionsQuery__
 *
 * To run a query within a React component, call `useGetDineInSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDineInSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDineInSectionsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetDineInSectionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >(GetDineInSectionsDocument, options);
}
export function useGetDineInSectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetDineInSectionsQuery,
    GetDineInSectionsQueryVariables
  >(GetDineInSectionsDocument, options);
}
export type GetDineInSectionsQueryHookResult = ReturnType<
  typeof useGetDineInSectionsQuery
>;
export type GetDineInSectionsLazyQueryHookResult = ReturnType<
  typeof useGetDineInSectionsLazyQuery
>;
export type GetDineInSectionsQueryResult = ApolloReactCommon.QueryResult<
  GetDineInSectionsQuery,
  GetDineInSectionsQueryVariables
>;
export const AddDineInSectionsDocument = gql`
  mutation addDineInSections($dineInSectionInput: DineInSectionInput!) {
    upsertDineInSection(dineInSectionInput: $dineInSectionInput) {
      id
    }
  }
`;
export type AddDineInSectionsMutationFn = ApolloReactCommon.MutationFunction<
  AddDineInSectionsMutation,
  AddDineInSectionsMutationVariables
>;

/**
 * __useAddDineInSectionsMutation__
 *
 * To run a mutation, you first call `useAddDineInSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDineInSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDineInSectionsMutation, { data, loading, error }] = useAddDineInSectionsMutation({
 *   variables: {
 *      dineInSectionInput: // value for 'dineInSectionInput'
 *   },
 * });
 */
export function useAddDineInSectionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddDineInSectionsMutation,
    AddDineInSectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddDineInSectionsMutation,
    AddDineInSectionsMutationVariables
  >(AddDineInSectionsDocument, options);
}
export type AddDineInSectionsMutationHookResult = ReturnType<
  typeof useAddDineInSectionsMutation
>;
export type AddDineInSectionsMutationResult =
  ApolloReactCommon.MutationResult<AddDineInSectionsMutation>;
export type AddDineInSectionsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddDineInSectionsMutation,
    AddDineInSectionsMutationVariables
  >;
export const DeleteDineInSectionsDocument = gql`
  mutation deleteDineInSections($id: ID!, $propertyID: String!) {
    deleteDineInSection(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteDineInSectionsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDineInSectionsMutation,
  DeleteDineInSectionsMutationVariables
>;

/**
 * __useDeleteDineInSectionsMutation__
 *
 * To run a mutation, you first call `useDeleteDineInSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDineInSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDineInSectionsMutation, { data, loading, error }] = useDeleteDineInSectionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteDineInSectionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDineInSectionsMutation,
    DeleteDineInSectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteDineInSectionsMutation,
    DeleteDineInSectionsMutationVariables
  >(DeleteDineInSectionsDocument, options);
}
export type DeleteDineInSectionsMutationHookResult = ReturnType<
  typeof useDeleteDineInSectionsMutation
>;
export type DeleteDineInSectionsMutationResult =
  ApolloReactCommon.MutationResult<DeleteDineInSectionsMutation>;
export type DeleteDineInSectionsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteDineInSectionsMutation,
    DeleteDineInSectionsMutationVariables
  >;
export const GetEdibleDocument = gql`
  query getEdible($propertyID: String!) {
    getEdibles(propertyID: $propertyID) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      allergens
      extraOptions {
        name
        price
      }
      is_Activated
      section
      is_recommended
      spice_level
      sequence
      selections {
        id
        name
        limit
        selection_items {
          id
          name
          price
        }
      }
    }
  }
`;

/**
 * __useGetEdibleQuery__
 *
 * To run a query within a React component, call `useGetEdibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEdibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEdibleQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetEdibleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetEdibleQuery,
    GetEdibleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetEdibleQuery, GetEdibleQueryVariables>(
    GetEdibleDocument,
    options
  );
}
export function useGetEdibleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEdibleQuery,
    GetEdibleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetEdibleQuery, GetEdibleQueryVariables>(
    GetEdibleDocument,
    options
  );
}
export type GetEdibleQueryHookResult = ReturnType<typeof useGetEdibleQuery>;
export type GetEdibleLazyQueryHookResult = ReturnType<
  typeof useGetEdibleLazyQuery
>;
export type GetEdibleQueryResult = ApolloReactCommon.QueryResult<
  GetEdibleQuery,
  GetEdibleQueryVariables
>;
export const AddEdibleDocument = gql`
  mutation addEdible($dineInItemInput: DineInItemInput!) {
    upsertEdible(dineInItemInput: $dineInItemInput) {
      id
    }
  }
`;
export type AddEdibleMutationFn = ApolloReactCommon.MutationFunction<
  AddEdibleMutation,
  AddEdibleMutationVariables
>;

/**
 * __useAddEdibleMutation__
 *
 * To run a mutation, you first call `useAddEdibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEdibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEdibleMutation, { data, loading, error }] = useAddEdibleMutation({
 *   variables: {
 *      dineInItemInput: // value for 'dineInItemInput'
 *   },
 * });
 */
export function useAddEdibleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddEdibleMutation,
    AddEdibleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddEdibleMutation,
    AddEdibleMutationVariables
  >(AddEdibleDocument, options);
}
export type AddEdibleMutationHookResult = ReturnType<
  typeof useAddEdibleMutation
>;
export type AddEdibleMutationResult =
  ApolloReactCommon.MutationResult<AddEdibleMutation>;
export type AddEdibleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddEdibleMutation,
  AddEdibleMutationVariables
>;
export const DeleteEdibleDocument = gql`
  mutation deleteEdible($id: ID!, $propertyID: String!) {
    deleteEdible(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteEdibleMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEdibleMutation,
  DeleteEdibleMutationVariables
>;

/**
 * __useDeleteEdibleMutation__
 *
 * To run a mutation, you first call `useDeleteEdibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEdibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEdibleMutation, { data, loading, error }] = useDeleteEdibleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteEdibleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEdibleMutation,
    DeleteEdibleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEdibleMutation,
    DeleteEdibleMutationVariables
  >(DeleteEdibleDocument, options);
}
export type DeleteEdibleMutationHookResult = ReturnType<
  typeof useDeleteEdibleMutation
>;
export type DeleteEdibleMutationResult =
  ApolloReactCommon.MutationResult<DeleteEdibleMutation>;
export type DeleteEdibleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteEdibleMutation,
  DeleteEdibleMutationVariables
>;
export const UpdateEdibleStatusDocument = gql`
  mutation updateEdibleStatus(
    $id: ID!
    $propertyID: String!
    $status: Boolean!
  ) {
    updateEdibleStatus(id: $id, propertyID: $propertyID, status: $status) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      allergens
      extraOptions {
        name
        price
      }
      is_Activated
      section
    }
  }
`;
export type UpdateEdibleStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEdibleStatusMutation,
  UpdateEdibleStatusMutationVariables
>;

/**
 * __useUpdateEdibleStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEdibleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEdibleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEdibleStatusMutation, { data, loading, error }] = useUpdateEdibleStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEdibleStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEdibleStatusMutation,
    UpdateEdibleStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEdibleStatusMutation,
    UpdateEdibleStatusMutationVariables
  >(UpdateEdibleStatusDocument, options);
}
export type UpdateEdibleStatusMutationHookResult = ReturnType<
  typeof useUpdateEdibleStatusMutation
>;
export type UpdateEdibleStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateEdibleStatusMutation>;
export type UpdateEdibleStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEdibleStatusMutation,
    UpdateEdibleStatusMutationVariables
  >;
export const GetHotelDineInDocument = gql`
  query getHotelDineIn($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      dining {
        desc
        id
        img
        name
        property_id
      }
    }
  }
`;

/**
 * __useGetHotelDineInQuery__
 *
 * To run a query within a React component, call `useGetHotelDineInQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelDineInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelDineInQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetHotelDineInQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >(GetHotelDineInDocument, options);
}
export function useGetHotelDineInLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelDineInQuery,
    GetHotelDineInQueryVariables
  >(GetHotelDineInDocument, options);
}
export type GetHotelDineInQueryHookResult = ReturnType<
  typeof useGetHotelDineInQuery
>;
export type GetHotelDineInLazyQueryHookResult = ReturnType<
  typeof useGetHotelDineInLazyQuery
>;
export type GetHotelDineInQueryResult = ApolloReactCommon.QueryResult<
  GetHotelDineInQuery,
  GetHotelDineInQueryVariables
>;
export const AddHotelDineInDocument = gql`
  mutation addHotelDineIn($diningInput: DiningInput!) {
    upsertDining(diningInput: $diningInput)
  }
`;
export type AddHotelDineInMutationFn = ApolloReactCommon.MutationFunction<
  AddHotelDineInMutation,
  AddHotelDineInMutationVariables
>;

/**
 * __useAddHotelDineInMutation__
 *
 * To run a mutation, you first call `useAddHotelDineInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHotelDineInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHotelDineInMutation, { data, loading, error }] = useAddHotelDineInMutation({
 *   variables: {
 *      diningInput: // value for 'diningInput'
 *   },
 * });
 */
export function useAddHotelDineInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddHotelDineInMutation,
    AddHotelDineInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddHotelDineInMutation,
    AddHotelDineInMutationVariables
  >(AddHotelDineInDocument, options);
}
export type AddHotelDineInMutationHookResult = ReturnType<
  typeof useAddHotelDineInMutation
>;
export type AddHotelDineInMutationResult =
  ApolloReactCommon.MutationResult<AddHotelDineInMutation>;
export type AddHotelDineInMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddHotelDineInMutation,
    AddHotelDineInMutationVariables
  >;
export const DeleteDiningDocument = gql`
  mutation deleteDining($id: ID!, $propertyID: String!) {
    deleteDining(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteDiningMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDiningMutation,
  DeleteDiningMutationVariables
>;

/**
 * __useDeleteDiningMutation__
 *
 * To run a mutation, you first call `useDeleteDiningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiningMutation, { data, loading, error }] = useDeleteDiningMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteDiningMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDiningMutation,
    DeleteDiningMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteDiningMutation,
    DeleteDiningMutationVariables
  >(DeleteDiningDocument, options);
}
export type DeleteDiningMutationHookResult = ReturnType<
  typeof useDeleteDiningMutation
>;
export type DeleteDiningMutationResult =
  ApolloReactCommon.MutationResult<DeleteDiningMutation>;
export type DeleteDiningMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDiningMutation,
  DeleteDiningMutationVariables
>;
export const GetHotelInformationDocument = gql`
  query getHotelInformation($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      hotel_name
      property_id
      dir_con {
        address
        email
        img
        phone
      }
      feedback_desc
      promotion_desc
      about {
        desc
        img
      }
      dining_image
      hotel_services_image
      rooms_image
      directory_description
      is_Activated {
        about
        dining
        directory
        hotel_rooms
        hotel_services
      }
    }
  }
`;

/**
 * __useGetHotelInformationQuery__
 *
 * To run a query within a React component, call `useGetHotelInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelInformationQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetHotelInformationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >(GetHotelInformationDocument, options);
}
export function useGetHotelInformationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelInformationQuery,
    GetHotelInformationQueryVariables
  >(GetHotelInformationDocument, options);
}
export type GetHotelInformationQueryHookResult = ReturnType<
  typeof useGetHotelInformationQuery
>;
export type GetHotelInformationLazyQueryHookResult = ReturnType<
  typeof useGetHotelInformationLazyQuery
>;
export type GetHotelInformationQueryResult = ApolloReactCommon.QueryResult<
  GetHotelInformationQuery,
  GetHotelInformationQueryVariables
>;
export const UpdatePropertyDocument = gql`
  mutation updateProperty($propertyInput: PropertyInput!) {
    upsertProperty(propertyInput: $propertyInput) {
      id
    }
  }
`;
export type UpdatePropertyMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >(UpdatePropertyDocument, options);
}
export type UpdatePropertyMutationHookResult = ReturnType<
  typeof useUpdatePropertyMutation
>;
export type UpdatePropertyMutationResult =
  ApolloReactCommon.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePropertyMutation,
    UpdatePropertyMutationVariables
  >;
export const UpdateMetaDataDocument = gql`
  mutation updateMetaData($metaInput: MetaDataInput!) {
    upsertMetaData(metaInput: $metaInput)
  }
`;
export type UpdateMetaDataMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMetaDataMutation,
  UpdateMetaDataMutationVariables
>;

/**
 * __useUpdateMetaDataMutation__
 *
 * To run a mutation, you first call `useUpdateMetaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetaDataMutation, { data, loading, error }] = useUpdateMetaDataMutation({
 *   variables: {
 *      metaInput: // value for 'metaInput'
 *   },
 * });
 */
export function useUpdateMetaDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMetaDataMutation,
    UpdateMetaDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateMetaDataMutation,
    UpdateMetaDataMutationVariables
  >(UpdateMetaDataDocument, options);
}
export type UpdateMetaDataMutationHookResult = ReturnType<
  typeof useUpdateMetaDataMutation
>;
export type UpdateMetaDataMutationResult =
  ApolloReactCommon.MutationResult<UpdateMetaDataMutation>;
export type UpdateMetaDataMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateMetaDataMutation,
    UpdateMetaDataMutationVariables
  >;
export const UpdateApiKeyDocument = gql`
  mutation updateApiKey($apiKey: String!, $propertyID: String!) {
    upsertApiKey(apiKey: $apiKey, propertyID: $propertyID)
  }
`;
export type UpdateApiKeyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;

/**
 * __useUpdateApiKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyMutation, { data, loading, error }] = useUpdateApiKeyMutation({
 *   variables: {
 *      apiKey: // value for 'apiKey'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useUpdateApiKeyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateApiKeyMutation,
    UpdateApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateApiKeyMutation,
    UpdateApiKeyMutationVariables
  >(UpdateApiKeyDocument, options);
}
export type UpdateApiKeyMutationHookResult = ReturnType<
  typeof useUpdateApiKeyMutation
>;
export type UpdateApiKeyMutationResult =
  ApolloReactCommon.MutationResult<UpdateApiKeyMutation>;
export type UpdateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateApiKeyMutation,
  UpdateApiKeyMutationVariables
>;
export const GetHotelServicesDocument = gql`
  query getHotelServices($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      hotel_services {
        desc
        id
        img
        name
        property_id
        icon
        uploaded_icon
        sequence
      }
    }
  }
`;

/**
 * __useGetHotelServicesQuery__
 *
 * To run a query within a React component, call `useGetHotelServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelServicesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetHotelServicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >(GetHotelServicesDocument, options);
}
export function useGetHotelServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelServicesQuery,
    GetHotelServicesQueryVariables
  >(GetHotelServicesDocument, options);
}
export type GetHotelServicesQueryHookResult = ReturnType<
  typeof useGetHotelServicesQuery
>;
export type GetHotelServicesLazyQueryHookResult = ReturnType<
  typeof useGetHotelServicesLazyQuery
>;
export type GetHotelServicesQueryResult = ApolloReactCommon.QueryResult<
  GetHotelServicesQuery,
  GetHotelServicesQueryVariables
>;
export const GetHotelServiceDocument = gql`
  query getHotelService($propertyID: String!, $id: String!) {
    getHotelService(propertyID: $propertyID, id: $id) {
      id
      img
      name
      desc
      property_id
      sub_services {
        name
        img
        desc
        booking_url
        service_id
      }
      icon
    }
  }
`;

/**
 * __useGetHotelServiceQuery__
 *
 * To run a query within a React component, call `useGetHotelServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelServiceQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHotelServiceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >(GetHotelServiceDocument, options);
}
export function useGetHotelServiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHotelServiceQuery,
    GetHotelServiceQueryVariables
  >(GetHotelServiceDocument, options);
}
export type GetHotelServiceQueryHookResult = ReturnType<
  typeof useGetHotelServiceQuery
>;
export type GetHotelServiceLazyQueryHookResult = ReturnType<
  typeof useGetHotelServiceLazyQuery
>;
export type GetHotelServiceQueryResult = ApolloReactCommon.QueryResult<
  GetHotelServiceQuery,
  GetHotelServiceQueryVariables
>;
export const AddServicesDocument = gql`
  mutation addServices($hotelServicesInput: HotelServiceInput!) {
    upsertHotelService(hotelServiceInput: $hotelServicesInput)
  }
`;
export type AddServicesMutationFn = ApolloReactCommon.MutationFunction<
  AddServicesMutation,
  AddServicesMutationVariables
>;

/**
 * __useAddServicesMutation__
 *
 * To run a mutation, you first call `useAddServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServicesMutation, { data, loading, error }] = useAddServicesMutation({
 *   variables: {
 *      hotelServicesInput: // value for 'hotelServicesInput'
 *   },
 * });
 */
export function useAddServicesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddServicesMutation,
    AddServicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddServicesMutation,
    AddServicesMutationVariables
  >(AddServicesDocument, options);
}
export type AddServicesMutationHookResult = ReturnType<
  typeof useAddServicesMutation
>;
export type AddServicesMutationResult =
  ApolloReactCommon.MutationResult<AddServicesMutation>;
export type AddServicesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddServicesMutation,
  AddServicesMutationVariables
>;
export const DeleteHotelServiceDocument = gql`
  mutation deleteHotelService($id: ID!, $propertyID: String!) {
    deleteHotelService(id: $id, propertyID: $propertyID)
  }
`;
export type DeleteHotelServiceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteHotelServiceMutation,
  DeleteHotelServiceMutationVariables
>;

/**
 * __useDeleteHotelServiceMutation__
 *
 * To run a mutation, you first call `useDeleteHotelServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHotelServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHotelServiceMutation, { data, loading, error }] = useDeleteHotelServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeleteHotelServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteHotelServiceMutation,
    DeleteHotelServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteHotelServiceMutation,
    DeleteHotelServiceMutationVariables
  >(DeleteHotelServiceDocument, options);
}
export type DeleteHotelServiceMutationHookResult = ReturnType<
  typeof useDeleteHotelServiceMutation
>;
export type DeleteHotelServiceMutationResult =
  ApolloReactCommon.MutationResult<DeleteHotelServiceMutation>;
export type DeleteHotelServiceMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteHotelServiceMutation,
    DeleteHotelServiceMutationVariables
  >;
export const GetPromotionDocument = gql`
  query getPromotion($propertyID: String!) {
    getAdminPromotions(propertyID: $propertyID) {
      banner_image
      description
      end_date
      id
      name
      property_id
      start_date
      is_Activated
    }
  }
`;

/**
 * __useGetPromotionQuery__
 *
 * To run a query within a React component, call `useGetPromotionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPromotionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >(GetPromotionDocument, options);
}
export function useGetPromotionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPromotionQuery,
    GetPromotionQueryVariables
  >(GetPromotionDocument, options);
}
export type GetPromotionQueryHookResult = ReturnType<
  typeof useGetPromotionQuery
>;
export type GetPromotionLazyQueryHookResult = ReturnType<
  typeof useGetPromotionLazyQuery
>;
export type GetPromotionQueryResult = ApolloReactCommon.QueryResult<
  GetPromotionQuery,
  GetPromotionQueryVariables
>;
export const AddPromotionDocument = gql`
  mutation addPromotion($promotionInput: PromotionInput!) {
    upsertPromotion(promotionInput: $promotionInput) {
      id
    }
  }
`;
export type AddPromotionMutationFn = ApolloReactCommon.MutationFunction<
  AddPromotionMutation,
  AddPromotionMutationVariables
>;

/**
 * __useAddPromotionMutation__
 *
 * To run a mutation, you first call `useAddPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromotionMutation, { data, loading, error }] = useAddPromotionMutation({
 *   variables: {
 *      promotionInput: // value for 'promotionInput'
 *   },
 * });
 */
export function useAddPromotionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPromotionMutation,
    AddPromotionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddPromotionMutation,
    AddPromotionMutationVariables
  >(AddPromotionDocument, options);
}
export type AddPromotionMutationHookResult = ReturnType<
  typeof useAddPromotionMutation
>;
export type AddPromotionMutationResult =
  ApolloReactCommon.MutationResult<AddPromotionMutation>;
export type AddPromotionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPromotionMutation,
  AddPromotionMutationVariables
>;
export const DeletePromotionsDocument = gql`
  mutation deletePromotions($id: ID!, $propertyID: String!) {
    deletePromotion(id: $id, propertyID: $propertyID)
  }
`;
export type DeletePromotionsMutationFn = ApolloReactCommon.MutationFunction<
  DeletePromotionsMutation,
  DeletePromotionsMutationVariables
>;

/**
 * __useDeletePromotionsMutation__
 *
 * To run a mutation, you first call `useDeletePromotionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromotionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromotionsMutation, { data, loading, error }] = useDeletePromotionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDeletePromotionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePromotionsMutation,
    DeletePromotionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePromotionsMutation,
    DeletePromotionsMutationVariables
  >(DeletePromotionsDocument, options);
}
export type DeletePromotionsMutationHookResult = ReturnType<
  typeof useDeletePromotionsMutation
>;
export type DeletePromotionsMutationResult =
  ApolloReactCommon.MutationResult<DeletePromotionsMutation>;
export type DeletePromotionsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeletePromotionsMutation,
    DeletePromotionsMutationVariables
  >;
export const UpdatePromotionStatusDocument = gql`
  mutation updatePromotionStatus(
    $id: ID!
    $propertyID: String!
    $status: Boolean!
  ) {
    updatePromotionStatus(id: $id, propertyID: $propertyID, status: $status) {
      banner_image
      description
      end_date
      id
      name
      property_id
      start_date
      is_Activated
    }
  }
`;
export type UpdatePromotionStatusMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >;

/**
 * __useUpdatePromotionStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionStatusMutation, { data, loading, error }] = useUpdatePromotionStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePromotionStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >(UpdatePromotionStatusDocument, options);
}
export type UpdatePromotionStatusMutationHookResult = ReturnType<
  typeof useUpdatePromotionStatusMutation
>;
export type UpdatePromotionStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdatePromotionStatusMutation>;
export type UpdatePromotionStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePromotionStatusMutation,
    UpdatePromotionStatusMutationVariables
  >;
export const GetPropertyDetailsDocument = gql`
  query getPropertyDetails($propertyID: String!) {
    getProperty(propertyID: $propertyID) {
      chat_api_url
      domain
      hotlync_api_url
      id
      img
      name
      primary_color
      primary_light
      primary_text_color
      secondary_text_color
      currency
      background_color
      font
      tile_color
      by_dining
      show_talk_to_us
      show_main_category
      has_simphony_pos
      simphony_pos_url
      direct_simphony_pos_check
      pos_loc_ref
      pos_rvc_ref
    }
  }
`;

/**
 * __useGetPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useGetPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDetailsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >(GetPropertyDetailsDocument, options);
}
export function useGetPropertyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >(GetPropertyDetailsDocument, options);
}
export type GetPropertyDetailsQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsQuery
>;
export type GetPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsLazyQuery
>;
export type GetPropertyDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyDetailsQuery,
  GetPropertyDetailsQueryVariables
>;
export const GetRoomsDocument = gql`
  query getRooms($propertyID: String!) {
    getMeta(propertyID: $propertyID) {
      id
      hotel_rooms {
        desc
        id
        img
        property_id
        room_type
      }
    }
  }
`;

/**
 * __useGetRoomsQuery__
 *
 * To run a query within a React component, call `useGetRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetRoomsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRoomsQuery,
    GetRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetRoomsQuery, GetRoomsQueryVariables>(
    GetRoomsDocument,
    options
  );
}
export function useGetRoomsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRoomsQuery,
    GetRoomsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetRoomsQuery, GetRoomsQueryVariables>(
    GetRoomsDocument,
    options
  );
}
export type GetRoomsQueryHookResult = ReturnType<typeof useGetRoomsQuery>;
export type GetRoomsLazyQueryHookResult = ReturnType<
  typeof useGetRoomsLazyQuery
>;
export type GetRoomsQueryResult = ApolloReactCommon.QueryResult<
  GetRoomsQuery,
  GetRoomsQueryVariables
>;
export const AddRoomDocument = gql`
  mutation addRoom($roomInput: RoomInput!) {
    upsertRoom(roomInput: $roomInput) {
      id
    }
  }
`;
export type AddRoomMutationFn = ApolloReactCommon.MutationFunction<
  AddRoomMutation,
  AddRoomMutationVariables
>;

/**
 * __useAddRoomMutation__
 *
 * To run a mutation, you first call `useAddRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoomMutation, { data, loading, error }] = useAddRoomMutation({
 *   variables: {
 *      roomInput: // value for 'roomInput'
 *   },
 * });
 */
export function useAddRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddRoomMutation,
    AddRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddRoomMutation,
    AddRoomMutationVariables
  >(AddRoomDocument, options);
}
export type AddRoomMutationHookResult = ReturnType<typeof useAddRoomMutation>;
export type AddRoomMutationResult =
  ApolloReactCommon.MutationResult<AddRoomMutation>;
export type AddRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddRoomMutation,
  AddRoomMutationVariables
>;
export const DeleteRoomDocument = gql`
  mutation deleteRoom($id: ID!) {
    deleteRoom(id: $id)
  }
`;
export type DeleteRoomMutationFn = ApolloReactCommon.MutationFunction<
  DeleteRoomMutation,
  DeleteRoomMutationVariables
>;

/**
 * __useDeleteRoomMutation__
 *
 * To run a mutation, you first call `useDeleteRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomMutation, { data, loading, error }] = useDeleteRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteRoomMutation,
    DeleteRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteRoomMutation,
    DeleteRoomMutationVariables
  >(DeleteRoomDocument, options);
}
export type DeleteRoomMutationHookResult = ReturnType<
  typeof useDeleteRoomMutation
>;
export type DeleteRoomMutationResult =
  ApolloReactCommon.MutationResult<DeleteRoomMutation>;
export type DeleteRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteRoomMutation,
  DeleteRoomMutationVariables
>;
export const GetUploadSignedUrlDocument = gql`
  query getUploadSignedURL(
    $fileType: String!
    $extension: String!
    $propertyID: String!
  ) {
    getUploadSignedURL(
      fileType: $fileType
      extension: $extension
      propertyID: $propertyID
    ) {
      url
      presigned_upload_url
    }
  }
`;

/**
 * __useGetUploadSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetUploadSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadSignedUrlQuery({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      extension: // value for 'extension'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetUploadSignedUrlQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >(GetUploadSignedUrlDocument, options);
}
export function useGetUploadSignedUrlLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUploadSignedUrlQuery,
    GetUploadSignedUrlQueryVariables
  >(GetUploadSignedUrlDocument, options);
}
export type GetUploadSignedUrlQueryHookResult = ReturnType<
  typeof useGetUploadSignedUrlQuery
>;
export type GetUploadSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetUploadSignedUrlLazyQuery
>;
export type GetUploadSignedUrlQueryResult = ApolloReactCommon.QueryResult<
  GetUploadSignedUrlQuery,
  GetUploadSignedUrlQueryVariables
>;
export const SyncSimphonyPosDocument = gql`
  mutation syncSimphonyPOS($propertyID: String!) {
    syncSimphonyPOSMenuItems(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;
export type SyncSimphonyPosMutationFn = ApolloReactCommon.MutationFunction<
  SyncSimphonyPosMutation,
  SyncSimphonyPosMutationVariables
>;

/**
 * __useSyncSimphonyPosMutation__
 *
 * To run a mutation, you first call `useSyncSimphonyPosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSimphonyPosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSimphonyPosMutation, { data, loading, error }] = useSyncSimphonyPosMutation({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSyncSimphonyPosMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SyncSimphonyPosMutation,
    SyncSimphonyPosMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SyncSimphonyPosMutation,
    SyncSimphonyPosMutationVariables
  >(SyncSimphonyPosDocument, options);
}
export type SyncSimphonyPosMutationHookResult = ReturnType<
  typeof useSyncSimphonyPosMutation
>;
export type SyncSimphonyPosMutationResult =
  ApolloReactCommon.MutationResult<SyncSimphonyPosMutation>;
export type SyncSimphonyPosMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SyncSimphonyPosMutation,
    SyncSimphonyPosMutationVariables
  >;
export const CreateSimphonyWithComboMealsDocument = gql`
  mutation createSimphonyWithComboMeals($propertyID: String!) {
    createSimphonyWithComboMeals(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;
export type CreateSimphonyWithComboMealsMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >;

/**
 * __useCreateSimphonyWithComboMealsMutation__
 *
 * To run a mutation, you first call `useCreateSimphonyWithComboMealsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimphonyWithComboMealsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimphonyWithComboMealsMutation, { data, loading, error }] = useCreateSimphonyWithComboMealsMutation({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useCreateSimphonyWithComboMealsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >(CreateSimphonyWithComboMealsDocument, options);
}
export type CreateSimphonyWithComboMealsMutationHookResult = ReturnType<
  typeof useCreateSimphonyWithComboMealsMutation
>;
export type CreateSimphonyWithComboMealsMutationResult =
  ApolloReactCommon.MutationResult<CreateSimphonyWithComboMealsMutation>;
export type CreateSimphonyWithComboMealsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateSimphonyWithComboMealsMutation,
    CreateSimphonyWithComboMealsMutationVariables
  >;
export const SimphonyDiscountsDocument = gql`
  query simphonyDiscounts($propertyID: String!) {
    simphonyDiscounts(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;

/**
 * __useSimphonyDiscountsQuery__
 *
 * To run a query within a React component, call `useSimphonyDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimphonyDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimphonyDiscountsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSimphonyDiscountsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >(SimphonyDiscountsDocument, options);
}
export function useSimphonyDiscountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SimphonyDiscountsQuery,
    SimphonyDiscountsQueryVariables
  >(SimphonyDiscountsDocument, options);
}
export type SimphonyDiscountsQueryHookResult = ReturnType<
  typeof useSimphonyDiscountsQuery
>;
export type SimphonyDiscountsLazyQueryHookResult = ReturnType<
  typeof useSimphonyDiscountsLazyQuery
>;
export type SimphonyDiscountsQueryResult = ApolloReactCommon.QueryResult<
  SimphonyDiscountsQuery,
  SimphonyDiscountsQueryVariables
>;
export const SimphonyServiceChargesDocument = gql`
  query simphonyServiceCharges($propertyID: String!) {
    simphonyServiceCharges(propertyID: $propertyID) {
      status
      message
      data
    }
  }
`;

/**
 * __useSimphonyServiceChargesQuery__
 *
 * To run a query within a React component, call `useSimphonyServiceChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimphonyServiceChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimphonyServiceChargesQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useSimphonyServiceChargesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >(SimphonyServiceChargesDocument, options);
}
export function useSimphonyServiceChargesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SimphonyServiceChargesQuery,
    SimphonyServiceChargesQueryVariables
  >(SimphonyServiceChargesDocument, options);
}
export type SimphonyServiceChargesQueryHookResult = ReturnType<
  typeof useSimphonyServiceChargesQuery
>;
export type SimphonyServiceChargesLazyQueryHookResult = ReturnType<
  typeof useSimphonyServiceChargesLazyQuery
>;
export type SimphonyServiceChargesQueryResult = ApolloReactCommon.QueryResult<
  SimphonyServiceChargesQuery,
  SimphonyServiceChargesQueryVariables
>;
