import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Remove, Delete } from "@mui/icons-material";
import {
  Allergens,
  Diet_Type,
  DineInCategories,
  DineInItem,
  DineInSection,
  useAddEdibleMutation,
  useGetUploadSignedUrlLazyQuery,
} from "../../generated/graphql";
import swal from "sweetalert";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ExtraHotIcon } from "../../components/icons/ExtraHotIcon";
import { HotIcon } from "../../components/icons/HotIcon";
import { MediumIcon } from "../../components/icons/MediumIcon";
import { MildIcon } from "../../components/icons/MildIcon";
import useEdible from "./hotel-edible-hook";
import { v4 as uuidv4 } from 'uuid';

interface prop {
  editData: DineInItem | null;
  propertyId: string;
  setOpen: (v: boolean) => void;
  refetchItems: () => void;
  handleEditClose: (reason:string) => void;
  open: boolean;
  categories: Array<DineInCategories | null> | null | undefined;
  sections: Array<DineInSection | null> | null | undefined;
  allergens: Array<Allergens | null> | null | undefined;
}

interface nestedProp {
  nestIndex:number,
  control:any,
  register:any
}

const NestedItems = ({ nestIndex, control, register }:nestedProp)=>{

  const { fields:itemFields,
    remove:itemRemove
   } = useFieldArray({ 
    control,
    name: `selections.${nestIndex}.selection_items`
  });
  return(
    <>
    {itemFields.map((item,index)=>{
      return (
        <>
        <Grid item md={6}>
          <TextField
            required
            fullWidth
            label="Item name"
            {...register(`selections.${nestIndex}.selection_items.${index}.name`)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => itemRemove(index)}
                    edge="end"
                    sx={{color:"#E6170A"}}
                  >
                    {<Delete />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            required
            fullWidth
            label="Item price"
            {...register(`selections.${nestIndex}.selection_items.${index}.price`)}
          />
        </Grid>
        </>
      )
    })}
    </>
  )
}

const DineInMenuForm = ({
  editData,
  propertyId,
  setOpen,
  refetchItems,
  handleEditClose,
  open,
  categories,
  sections,
  allergens,
}: prop) => {
  const { Diet, MenuProps } = useEdible();
  const chilli = [
    {
      name: "MildIcon",
      icon: <MildIcon />,
      level: "Mild",
    },
    {
      name: "MediumIcon",
      icon: <MediumIcon />,
      level: "Medium",
    },
    {
      name: "HotIcon",
      icon: <HotIcon />,
      level: "Hot",
    },
    {
      name: "ExtraHotIcon",
      icon: <ExtraHotIcon />,
      level: "Extra Hot",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    control,
    reset,
    getValues,
    setValue
  } = useForm<DineInItem>({
    defaultValues: {
      desc: editData?.desc || "",
      name: editData?.name || "",
      type: editData?.type,
      price: editData?.price,
      isVegan: editData?.isVegan,
      extraOptions: editData?.extraOptions,
      img: editData?.img || "",
      allergens: editData?.allergens,
      section: editData?.section,
      is_recommended: editData?.is_recommended || false,
      is_Activated:editData?.is_Activated || true,
      selections: editData?.selections
    },
  });

  React.useEffect(() => {
    return reset(
      editData || {
        desc: "",
        name: "",
        type: "",
        price: 0,
        isVegan: Diet_Type.None,
        extraOptions: [],
        img: "",
        allergens: [],
        section: [],
        is_recommended: false,
        is_Activated:true,
        selections: []
      }
    );
  }, [editData, reset]);

  const { fields, append, remove } = useFieldArray({
    name: "extraOptions",
    control,
  });

  const { fields:selectionFields , 
    append: selectionAppend , 
    remove:selectionRemove } = useFieldArray({
    name: "selections",
    control,
  });

  const [getUploadSignedURL, { loading: loadingImage }] =
    useGetUploadSignedUrlLazyQuery({
      fetchPolicy: "network-only",
    });

  const [upsertEdible] = useAddEdibleMutation();

  const [image, setImage] = React.useState<any>("");
  const [uploadImg, setUploadImg] = React.useState<any>("");
  const [isDisable, setIsDisable] = React.useState(true);

  const onSubmit = async (data: DineInItem) => {

    data = JSON.parse(JSON.stringify(data, (name, val) => {
      if (name === '__typename') {
          delete val[name];
      } else {
          return val;
      }
    }));
    // let extraOption = data.extraOptions?.map((extra) => {
    //   if (extra?.__typename) {
    //     delete extra?.__typename;
    //   }
    //   return extra;
    // });

    try {
      await upsertEdible({
        variables: {
          dineInItemInput: {
            id: editData?.id || "",
            desc: data.desc,
            img: image ? uploadImg : data.img,
            name: data.name,
            price: data.price,
            isVegan: data.isVegan,
            property_id: propertyId,
            type: data.type,
            extraOptions: data.extraOptions,
            allergens: data.allergens,
            is_Activated: data.is_Activated,
            section: data.section,
            is_recommended: data?.is_recommended,
            spice_level: data?.spice_level,
            selections: data?.selections
          },
        },
      });

      swal({
        text: "Item Added Successfully",
        icon: "success",
      });
      refetchItems();
      setOpen(false);
      setImage("");
      reset();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  const onCloseDialog=()=>{
    handleEditClose("dialogClose");
    reset();
  }
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      setImage(URL.createObjectURL(e.target.files[0]));

      const localFile = e.target.files[0];
      const filename = localFile.name;
      const fileType = localFile.type;
      const extension = filename.split(".")[1];
      const propertyID = propertyId;
      try {
        const { data: awsData } = await getUploadSignedURL({
          variables: {
            fileType,
            extension,
            propertyID,
          },
        });

        const {
          getUploadSignedURL: { presigned_upload_url, url: uploadedImageURL },
        } = awsData as any;

        const picture = await fetch(URL.createObjectURL(e.target.files[0]));
        const pictureBlob = await picture.blob();
        const file = new File([pictureBlob], filename);

        await fetch(presigned_upload_url, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": fileType,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setUploadImg(uploadedImageURL);
        setIsDisable(false);
      } catch (error) {
        swal({
          text: `${error}`,
          icon: "error",
        });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle
        color={"#fff"}
        bgcolor={(theme) => theme.palette.primary.main}
      >
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">
            {editData ? "Update Item" : "Add New Item"}
          </Typography>
          <IconButton color={"inherit"} onClick={onCloseDialog}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mt={2}>
            {/* Service*/}
            <Grid item md={12}>
              <TextField
                required
                fullWidth
                label="Name"
                {...register("name", {
                  maxLength: 60,
                  required: "Name is required!",
                })}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                rows={5}
                multiline
                label="Enter Description"
                {...register("desc")}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                type="number"
                min="0"
                required
                fullWidth
                label="Price"
                {...register("price", {
                  required: "Price is required!",
                })}
              />
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Section
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("section")}
                  label="Section"
                  defaultValue={editData?.section ? editData?.section : []}
                >
                  {sections?.map((section, index) => (
                    <MenuItem key={index} value={section?.name}>
                      {section?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* Multiple With Checkbox*/}
              {/* <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label" style={{ backgroundColor: "white", padding: "0 5px" }}> Select Section</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  {...register("section", {
                    
                  })}
                  defaultValue={editData?.section?editData?.section:[]}
                  // onChange={handleChange}
                  input={<OutlinedInput label="Select Section" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {sections?.map((section, index) => (
                    <MenuItem key={index} value={section?.name}>
                      <Checkbox checked={sections?.indexOf(section?.name || "") > -1} />
                      <ListItemText primary={section?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Category
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("type", {
                    required: "Category is required",
                  })}
                  label="Category"
                  defaultValue={editData?.type}
                >
                  {categories?.sort((a, b) => ((a?.name)as any).localeCompare(b?.name)as any)?.map((category, index) => (
                    <MenuItem key={index} value={category?.name}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Type{" "}
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("isVegan")}
                  label="Type"
                  defaultValue={editData?.isVegan}
                >
                  {Diet?.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ backgroundColor: "white", padding: "0 5px" }}
                >
                  Select Allergen
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  multiple
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("allergens", {
                    // required: "allergens is required",
                  })}
                  label="Allergen"
                  defaultValue={editData?.allergens ? editData?.allergens : []}
                >
                  {allergens?.map((allergen, index) => (
                    <MenuItem key={index} value={allergen?.icon || ""}>
                      {allergen?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Spice Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("spice_level", {})}
                  label="Section Icon"
                  defaultValue={editData?.spice_level}
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  {chilli.map((ic, index) => (
                    <MenuItem key={ic.name} value={ic.name}>
                      <ListItemIcon>{ic.icon}</ListItemIcon>
                      {ic.level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Controller
                    name={"is_recommended"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={props.value || false}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label="Chef's Recommended"
              />
            </Grid>
            {fields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Grid container spacing={2} mt={2} ml={0.1}>
                    <Grid item md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Extras name"
                        {...register(`extraOptions.${index}.name`)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        required
                        fullWidth
                        label="Extras price"
                        {...register(`extraOptions.${index}.price`)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Button
                        sx={{ marginTop: "5px" }}
                        variant="outlined"
                        type="button"
                        startIcon={<Remove />}
                        onClick={() => remove(index)}
                      >
                        DELETE
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid />
            <Box>
              <Grid container spacing={2} mt={1} ml={0}>
                <Grid item md={6}>
                  <Button
                    variant="contained"
                    type="button"
                    size="large"
                    startIcon={<Add />}
                    onClick={() =>
                      append({
                        name: "",
                        price: 0,
                      })
                    }
                  >
                    Extras
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {selectionFields.map((field, index) => {
              return (
                <React.Fragment key={field.id}>
                  <Grid container spacing={2} mt={2} ml={0.1}>
                    <Grid item md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Selection name"
                        {...register(`selections.${index}.name`)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Maximum Item Selection Limit"
                        type="number"
                        {...register(`selections.${index}.limit`)}
                      />
                    </Grid>
                    <NestedItems nestIndex={index} control={control} register={register}/>
                    <Grid item container md={12} justifyContent={"flex-end"}>
                      <Button variant="text" 
                        sx={{color:"red", 
                        fontSize:"12px",
                        mr:2}}
                        onClick={()=>selectionRemove(index)}
                      >
                        Delete Selection
                      </Button>
                      <Button 
                        variant="outlined"
                        onClick={()=>{
                          getValues() &&
                          setValue(
                            `selections.${index}.selection_items`,
                            [...(getValues(`selections.${index}.selection_items`) || []),
                            {id:uuidv4(),name:"",price:0}]
                            );
                        }}
                      >
                        Add Item
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}

            <Grid item md={12}>
              <Button
                  type="button"
                  size="large"
                  fullWidth
                  // startIcon={<Add />}
                  sx={{border:`1px dashed`}}
                  onClick={() =>
                    selectionAppend({
                      id: uuidv4(),
                      name: "",
                      selection_items:[]
                    })
                  }
                >
                  Add Selection
              </Button>
            </Grid>

            <Grid item md={12} mb={2}>
              <Typography color="text.secondary" gutterBottom>
                Select Image
              </Typography>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (editData?.img || image) && (
                  <Box>
                    <img
                      alt="Service"
                      width={"250px"}
                      src={image ? image : editData?.img}
                    />

                    {image && (
                      <Box
                        component={IconButton}
                        onClick={() => setImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
              <Button variant="outlined">
                <label>
                  {editData ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DineInMenuForm;
