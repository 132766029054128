import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function PeanutsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clip-path="url(#clip0_37_130)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#75B7E4"
        />
        <path
          d="M149.93 76.16V81.04L149.25 84.32L136.59 79.78L134.59 85.25L147.26 89.82C146.95 90.39 146.72 90.82 146.46 91.3C144.801 93.9848 142.755 96.4108 140.39 98.5C137.521 101.118 135.347 104.407 134.063 108.072C132.778 111.737 132.423 115.664 133.03 119.5C133.19 120.76 133.31 122.02 133.46 123.41L122.31 119.41C121.64 121.26 121 123.04 120.31 124.88L133.05 129.47L130.57 136.37L117.82 131.8L115.82 137.27L126.82 141.27C120.72 148.73 107.99 153.09 96.3096 147.27C84.2196 141.22 80.3096 127.76 82.8796 118.51L95.4796 123.02L97.4796 117.55L84.8196 112.99C85.0696 112.51 85.2696 112.1 85.4896 111.71C86.9781 109.145 88.8688 106.836 91.0896 104.87C94.0308 102.333 96.3001 99.1083 97.6957 95.4833C99.0913 91.8582 99.5701 87.9446 99.0896 84.09C98.9496 82.58 98.7495 81.09 98.5495 79.37L109.73 83.37L111.73 77.9L98.9896 73.31C99.8296 70.97 100.65 68.71 101.48 66.41L114.22 70.97L116.22 65.51L105.22 61.51C105.247 61.426 105.284 61.3454 105.33 61.27C105.451 61.1163 105.582 60.9694 105.72 60.83C111.64 54.83 118.82 52.08 127.18 53.02C132.295 53.5598 137.125 55.648 141.022 59.0056C144.919 62.3633 147.699 66.8304 148.99 71.81C149.4 73.23 149.59 74.71 149.93 76.16Z"
          fill="black"
        />
        <path
          d="M78.0901 50C79.8201 50.39 81.59 50.67 83.28 51.2C88.9238 52.9501 93.7708 56.6322 96.9701 61.6C97.071 61.7436 97.1386 61.9078 97.168 62.0808C97.1974 62.2538 97.1879 62.4312 97.1401 62.6C95.3634 65.7307 94.1038 69.1278 93.41 72.66H87.6801V78.48H92.8601C92.8601 82.59 94.13 86.54 93.17 90.59C92.3194 94.4693 90.2482 97.974 87.2601 100.59C81.1352 105.78 77.3017 113.174 76.5901 121.17C75.6701 131.02 78.8301 139.54 85.7301 146.65C85.9301 146.86 86.1501 147.05 86.3501 147.26C86.4029 147.36 86.4496 147.464 86.4901 147.57C78.3401 151.05 70.3601 150.91 62.7001 146.39C55.3601 142.06 51.2601 135.5 50.0801 126.95H63.5901V121.13H50.2201C50.5652 118.057 51.4989 115.08 52.9701 112.36C54.559 109.598 55.6268 106.568 56.1201 103.42C56.8309 98.5283 56.01 93.5365 53.7701 89.13L51.7701 85.13H63.5901V79.31H50.17V71.94H63.5901V66.16H51.7201C52.3301 64.94 52.7701 63.9 53.3401 62.94C55.2972 59.4525 58.0533 56.4787 61.3821 54.2624C64.7109 52.0461 68.5176 50.6505 72.4901 50.19C72.6751 50.1417 72.8556 50.0781 73.03 50H78.0901Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_130">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { PeanutsIcon };
