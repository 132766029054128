import React from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetRoomsQuery,
  useDeleteRoomMutation,
  Room,
} from "../../generated/graphql";

import swal from "sweetalert";

const useRooms = () => {
  const [editData, setEditData] = React.useState<Room | null>(null);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);

  const handleClickEditOpen = (data: Room | null) => {
    setEditData(data);
    setEditOpen(true);
    setIsDisable(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditData(null);
  };

  const [deleteRoom] = useDeleteRoomMutation();

  const { getUser } = useAuthProvider();
  const property = getUser();

  //Get All Rooms
  const {
    data: roomsData,
    loading: loadingRooms,
    refetch,
  } = useGetRoomsQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });

  const handleDeleteRoom = async (id: string) => {
    try {
      await deleteRoom({
        variables: {
          id: id,
        },
      });

      swal({
        text: "Room Deleted Successfully",
        icon: "success",
      });
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };

  return {
    roomsData,
    loadingRooms,
    refetch,
    handleClickEditOpen,
    handleEditClose,
    editOpen,
    editData,
    handleDeleteRoom,
    setEditOpen,
    propertyId: property.id,
    isDisable,
    setIsDisable,
  };
};

export default useRooms;
