import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function ExtraHotIcon(props: SvgIconProps) {
  return (
    <>
      <SvgIcon
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 49.829 49.829"
        xmlSpace="preserve"
        fill="#000000"
        style={{width:"21px"}}
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <path
              fill="#659C35"
              d="M43.302,7.914c-0.249,0-0.499-0.093-0.692-0.279c-0.398-0.382-0.411-1.016-0.028-1.414 c1.038-1.08,1.59-2.29,1.407-3.082c-0.118-0.509-0.576-0.912-1.362-1.199c-0.519-0.189-0.785-0.764-0.596-1.283 c0.189-0.519,0.765-0.783,1.283-0.596c1.819,0.665,2.431,1.796,2.623,2.627c0.342,1.478-0.374,3.316-1.914,4.92 C43.826,7.811,43.565,7.914,43.302,7.914z"
            />{" "}
            <path
              fill="#D13834"
              d="M32.238,15.303c-0.137,0.467-0.272,0.963-0.4,1.526c-3.5,15.417-16.372,23.55-30.887,31.589 c-0.664,0.368-0.755,1.503-0.103,1.405C10.2,48.417,34.61,50.788,46.549,22.81c0,0,6.163-12.856-1.712-14.981 C44.838,7.829,33.95,2.714,32.238,15.303z"
            />{" "}
            <path
              fill="fill:#88C057;"
              d="M48.974,17.608c0.892-4.035,0.86-8.868-3.839-10.136c0,0-10.635-4.987-13.19,6.315 c2.49-0.189,5.827-0.69,6.761-2.029c0,0,2.21,2.21,1.072,5.358c0,0,4.688-0.536,4.286-4.286c0,0,2.88,2.478,2.143,6.429 C46.206,19.259,47.596,18.838,48.974,17.608z"
            />{" "}
            <path
              fill="fill:#F75B57;"
              d="M14.928,45.996c-0.423,0-0.815-0.27-0.952-0.693c-0.169-0.525,0.119-1.089,0.645-1.259 c21.656-6.978,24.045-21.793,24.067-21.941c0.079-0.547,0.587-0.937,1.133-0.846c0.547,0.079,0.925,0.586,0.846,1.133 c-0.096,0.658-2.602,16.201-25.433,23.559C15.132,45.98,15.029,45.996,14.928,45.996z"
            />{" "}
          </g>{" "}
        </g>
      </SvgIcon>
      <SvgIcon
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 49.829 49.829"
        xmlSpace="preserve"
        fill="#000000"
        style={{width:"21px"}}
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <path
              fill="#659C35"
              d="M43.302,7.914c-0.249,0-0.499-0.093-0.692-0.279c-0.398-0.382-0.411-1.016-0.028-1.414 c1.038-1.08,1.59-2.29,1.407-3.082c-0.118-0.509-0.576-0.912-1.362-1.199c-0.519-0.189-0.785-0.764-0.596-1.283 c0.189-0.519,0.765-0.783,1.283-0.596c1.819,0.665,2.431,1.796,2.623,2.627c0.342,1.478-0.374,3.316-1.914,4.92 C43.826,7.811,43.565,7.914,43.302,7.914z"
            />{" "}
            <path
              fill="#D13834"
              d="M32.238,15.303c-0.137,0.467-0.272,0.963-0.4,1.526c-3.5,15.417-16.372,23.55-30.887,31.589 c-0.664,0.368-0.755,1.503-0.103,1.405C10.2,48.417,34.61,50.788,46.549,22.81c0,0,6.163-12.856-1.712-14.981 C44.838,7.829,33.95,2.714,32.238,15.303z"
            />{" "}
            <path
              fill="fill:#88C057;"
              d="M48.974,17.608c0.892-4.035,0.86-8.868-3.839-10.136c0,0-10.635-4.987-13.19,6.315 c2.49-0.189,5.827-0.69,6.761-2.029c0,0,2.21,2.21,1.072,5.358c0,0,4.688-0.536,4.286-4.286c0,0,2.88,2.478,2.143,6.429 C46.206,19.259,47.596,18.838,48.974,17.608z"
            />{" "}
            <path
              fill="fill:#F75B57;"
              d="M14.928,45.996c-0.423,0-0.815-0.27-0.952-0.693c-0.169-0.525,0.119-1.089,0.645-1.259 c21.656-6.978,24.045-21.793,24.067-21.941c0.079-0.547,0.587-0.937,1.133-0.846c0.547,0.079,0.925,0.586,0.846,1.133 c-0.096,0.658-2.602,16.201-25.433,23.559C15.132,45.98,15.029,45.996,14.928,45.996z"
            />{" "}
          </g>{" "}
        </g>
      </SvgIcon>
      <SvgIcon
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 49.829 49.829"
        xmlSpace="preserve"
        fill="#000000"
        style={{width:"21px"}}
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <path
              fill="#659C35"
              d="M43.302,7.914c-0.249,0-0.499-0.093-0.692-0.279c-0.398-0.382-0.411-1.016-0.028-1.414 c1.038-1.08,1.59-2.29,1.407-3.082c-0.118-0.509-0.576-0.912-1.362-1.199c-0.519-0.189-0.785-0.764-0.596-1.283 c0.189-0.519,0.765-0.783,1.283-0.596c1.819,0.665,2.431,1.796,2.623,2.627c0.342,1.478-0.374,3.316-1.914,4.92 C43.826,7.811,43.565,7.914,43.302,7.914z"
            />{" "}
            <path
              fill="#D13834"
              d="M32.238,15.303c-0.137,0.467-0.272,0.963-0.4,1.526c-3.5,15.417-16.372,23.55-30.887,31.589 c-0.664,0.368-0.755,1.503-0.103,1.405C10.2,48.417,34.61,50.788,46.549,22.81c0,0,6.163-12.856-1.712-14.981 C44.838,7.829,33.95,2.714,32.238,15.303z"
            />{" "}
            <path
              fill="fill:#88C057;"
              d="M48.974,17.608c0.892-4.035,0.86-8.868-3.839-10.136c0,0-10.635-4.987-13.19,6.315 c2.49-0.189,5.827-0.69,6.761-2.029c0,0,2.21,2.21,1.072,5.358c0,0,4.688-0.536,4.286-4.286c0,0,2.88,2.478,2.143,6.429 C46.206,19.259,47.596,18.838,48.974,17.608z"
            />{" "}
            <path
              fill="fill:#F75B57;"
              d="M14.928,45.996c-0.423,0-0.815-0.27-0.952-0.693c-0.169-0.525,0.119-1.089,0.645-1.259 c21.656-6.978,24.045-21.793,24.067-21.941c0.079-0.547,0.587-0.937,1.133-0.846c0.547,0.079,0.925,0.586,0.846,1.133 c-0.096,0.658-2.602,16.201-25.433,23.559C15.132,45.98,15.029,45.996,14.928,45.996z"
            />{" "}
          </g>{" "}
        </g>
      </SvgIcon>
      <SvgIcon
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 49.829 49.829"
        xmlSpace="preserve"
        fill="#000000"
        style={{width:"21px"}}
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <path
              fill="#659C35"
              d="M43.302,7.914c-0.249,0-0.499-0.093-0.692-0.279c-0.398-0.382-0.411-1.016-0.028-1.414 c1.038-1.08,1.59-2.29,1.407-3.082c-0.118-0.509-0.576-0.912-1.362-1.199c-0.519-0.189-0.785-0.764-0.596-1.283 c0.189-0.519,0.765-0.783,1.283-0.596c1.819,0.665,2.431,1.796,2.623,2.627c0.342,1.478-0.374,3.316-1.914,4.92 C43.826,7.811,43.565,7.914,43.302,7.914z"
            />{" "}
            <path
              fill="#D13834"
              d="M32.238,15.303c-0.137,0.467-0.272,0.963-0.4,1.526c-3.5,15.417-16.372,23.55-30.887,31.589 c-0.664,0.368-0.755,1.503-0.103,1.405C10.2,48.417,34.61,50.788,46.549,22.81c0,0,6.163-12.856-1.712-14.981 C44.838,7.829,33.95,2.714,32.238,15.303z"
            />{" "}
            <path
              fill="fill:#88C057;"
              d="M48.974,17.608c0.892-4.035,0.86-8.868-3.839-10.136c0,0-10.635-4.987-13.19,6.315 c2.49-0.189,5.827-0.69,6.761-2.029c0,0,2.21,2.21,1.072,5.358c0,0,4.688-0.536,4.286-4.286c0,0,2.88,2.478,2.143,6.429 C46.206,19.259,47.596,18.838,48.974,17.608z"
            />{" "}
            <path
              fill="fill:#F75B57;"
              d="M14.928,45.996c-0.423,0-0.815-0.27-0.952-0.693c-0.169-0.525,0.119-1.089,0.645-1.259 c21.656-6.978,24.045-21.793,24.067-21.941c0.079-0.547,0.587-0.937,1.133-0.846c0.547,0.079,0.925,0.586,0.846,1.133 c-0.096,0.658-2.602,16.201-25.433,23.559C15.132,45.98,15.029,45.996,14.928,45.996z"
            />{" "}
          </g>{" "}
        </g>
      </SvgIcon>
    </>
  );
}

export { ExtraHotIcon };
