import React from "react";
import AppRouter from "./pages/AppRouters";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./core/apolloClient";
import initAWSAmplify from "./core/awsAmplify";
import AuthContext from "./core/authContext";

initAWSAmplify();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthContext>
        <AppRouter />
      </AuthContext>
    </ApolloProvider>
  );
}

export default App;
