import { Add, Delete, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import useRooms from "./rooms-hook";
import Loader from "../../components/Loader";
import RoomsForm from "./rooms-form";

const Room = () => {
  const {
    roomsData,
    loadingRooms,
    handleClickEditOpen,
    handleEditClose,
    editOpen,
    editData,
    handleDeleteRoom,
    propertyId,
    setEditOpen,
    refetch,
  } = useRooms();
  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Room Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => handleClickEditOpen(null)}
            sx={{ mr: 1 }}
          >
            Add Room
          </Button>
        </Grid>
      </Grid>
      {loadingRooms ? (
        <Loader />
      ) : (
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {roomsData?.getMeta?.hotel_rooms?.map((room, index) => (
            <Grid item lg={4} md={6} key={index}>
              <Card
                sx={{ borderRadius: 0, position: "relative", height: "500px" }}
              >
                <Box
                  component={Button}
                  position="absolute"
                  top={5}
                  right={5}
                  variant="contained"
                  px={1}
                  minWidth="unset"
                  bgcolor={"#fff"}
                  onClick={() => handleClickEditOpen(room)}
                  color={"#000"}
                >
                  <EditOutlined />
                </Box>
                <CardMedia
                  sx={{
                    "&.MuiCardMedia-img": {
                      height: 250,
                    },
                  }}
                  component="img"
                  image={room.img}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {room.room_type}
                  </Typography>

                  <Typography variant="body1" color="text.secondary">
                    {room.desc}
                  </Typography>

                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    fontWeight={600}
                    mt={2}
                  ></Box>
                  <Tooltip title="Delete" placement="top" arrow>
                    <Box
                      component={Button}
                      position="absolute"
                      bottom={20}
                      right={15}
                      px={1}
                      minWidth="unset"
                      bgcolor={"#fff"}
                      onClick={() => handleDeleteRoom(room?.id as string)}
                      color={"#E6170A"}
                    >
                      <Delete />
                    </Box>
                  </Tooltip>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* Edit Dialog */}
          <RoomsForm
            editData={editData}
            editOpen={editOpen}
            handleEditClose={handleEditClose}
            propertyId={propertyId}
            refetch={refetch}
            setEditOpen={setEditOpen}
          />
        </Grid>
      )}
    </>
  );
};

export default Room;
