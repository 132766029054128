import { Add, EditOutlined} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useHotelServices from "./hotel-services-hook";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  useDraggable,
  Draggable,
  useDraggableContext,
  moveItems,
} from "react-tiny-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useAutoScroll } from "react-tiny-autoscroll";
import DeleteIcon from "@mui/icons-material/Delete";

const Item = ({
  id,
  name,
  sequence,
  img,
  listeners,
  isDragging,
  handleDelete,
  handleClickEditOpen,
}: {
  id: any;
  name: any;
  sequence: any;
  img: any;
  listeners: any;
  isDragging: any;
  handleDelete: any;
  handleClickEditOpen: any;
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
        {name}
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          alignItems: "center",
        }}
      >
         <img src={img} width={70} height={70} alt={name}></img>
      </div>
      <IconButton
        onClick={() => handleClickEditOpen()}
        color="info"
        aria-label="edit"
      >
        <EditOutlined />
      </IconButton>
      <IconButton
        onClick={() => handleDelete()}
        color="error"
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableItem = ({
  index,
  context,
  item,
  handleDelete,
  handleClickEditOpen,
}: {
  index: any;
  context: any;
  item: any;
  handleDelete: any;
  handleClickEditOpen: any;
}) => {
  const {
    listeners, // Handler listeners can be passed to Draggable component as well
    isDragging,
  } = useDraggable(context, index);

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        name={item.name}
        img={item.img}
        listeners={listeners}
        isDragging={isDragging}
        handleDelete={() => {
          handleDelete?.(item.id);
        }}
        handleClickEditOpen={() => {
          handleClickEditOpen?.(item);
        }}
      />
    </Draggable>
  );
};

const HotelServices = () => {
  const { servicesData, loadingServices, handleDelete,onSave } = useHotelServices();
  const [items, setItems] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (servicesData?.getMeta?.hotel_services) {
      setItems(
        servicesData?.getMeta?.hotel_services?.sort(
          (a, b) => (a?.sequence || 0) - (b?.sequence || 0)
        )
      );
    }
  }, [servicesData?.getMeta?.hotel_services]);

  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    nextItems.filter((item, i) => onSave({ ...item, sequence: i }));
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });
  if (loadingServices) {
    return <Loader />;
  }

  const handleEditClick = (id: string) => {
    navigate(`/hotel-services/${id}`);
  };

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} mb={2}>
        <Grid item>
          <Typography variant="h4" fontWeight={600}>
            Service Management
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"stretch"}>
          <Button
            size="large"
            disableElevation={false}
            variant="contained"
            startIcon={<Add />}
            onClick={() => navigate("/hotel-services/add-service")}
            sx={{ mr: 1 }}
          >
            Add Service
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Box
          ref={containerRef}
          sx={{
            border: "1px solid #ddd",
            overflowY: "auto",
            borderRadius: "4px",
            m: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              borderBottom: "1px solid #eee",
            }}
            style={{ height: "initial", opacity: 1, backgroundColor: "white" }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Sequence"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1.8,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Name"}
            </div>
            <div
              style={{
                display: "flex",
                flex: 2,
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              {"Image"}
            </div>
            <div
              style={{
                display: "flex",
                flex: "0.5",
                alignItems: "center",
                fontWeight: "bold",
              }}
            ></div>
          </Box>
          {items?.map((item: any, i: any) => (
            <DraggableItem
              context={context}
              key={item.id}
              index={i}
              item={item}
              handleDelete={handleDelete}
              handleClickEditOpen={() => handleEditClick(item.id)}
            />
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default HotelServices;
