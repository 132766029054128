import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function WheatIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/SvgIcon"
    >
      <g clip-path="url(#clip0_37_112)">
        <path
          d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z"
          fill="#7EC24A"
        />
        <path
          d="M73.6204 150V138C70.7336 137.779 67.9372 136.893 65.4503 135.41C62.5753 133.78 60.188 131.411 58.5353 128.549C56.8827 125.687 56.0248 122.435 56.0504 119.13C56.0504 116.73 56.0504 114.32 56.0504 111.92C63.3204 110.76 74.7704 116.18 76.5004 128.54C78.9204 117.54 85.6803 111.94 97.0703 111.65C96.8803 116.72 97.7404 121.65 95.6104 126.35C94.3036 129.518 92.1559 132.268 89.3994 134.304C86.6428 136.339 83.3824 137.583 79.9703 137.9C79.8752 137.91 79.7814 137.931 79.6903 137.96C79.6903 137.96 79.6903 137.96 79.5203 138.14V150H73.6204Z"
          fill="black"
        />
        <path
          d="M120.49 150V138C117.607 137.779 114.814 136.893 112.33 135.41C106.27 131.68 103.07 126.28 102.93 119.13C102.88 116.73 102.93 114.32 102.93 111.92C110.21 110.76 121.65 116.18 123.45 128.54C124.09 123.669 126.561 119.225 130.36 116.11C134.147 112.925 139.039 111.365 143.97 111.77C143.78 116.69 144.64 121.65 142.52 126.32C141.213 129.498 139.06 132.257 136.296 134.298C133.532 136.34 130.262 137.585 126.84 137.9L126.38 138V150H120.49Z"
          fill="black"
        />
        <path
          d="M56.05 64.97C64.6 63.97 74.8801 70.14 76.4901 81.69C78.9101 70.69 85.68 65.05 97.05 64.75C97.05 66.16 97.05 67.38 97.05 68.61C97.05 71.56 97.2901 74.55 96.3 77.4C95.8 78.84 95.12 80.21 94.54 81.61C94.4084 81.9542 94.1754 82.2503 93.8719 82.4592C93.5683 82.6682 93.2085 82.78 92.84 82.78C86.717 83.3716 81.0459 86.2626 76.97 90.87C76.7885 91.1014 76.5726 91.3037 76.33 91.47C76.2109 91.1663 76.0566 90.8776 75.8701 90.61C71.8565 86.1779 66.3397 83.3946 60.39 82.8C59.9925 82.7878 59.606 82.6667 59.2727 82.4498C58.9394 82.2329 58.6721 81.9285 58.5001 81.57C56.914 78.754 56.0676 75.5818 56.04 72.35C56.04 69.89 56.05 67.43 56.05 64.97Z"
          fill="black"
        />
        <path
          d="M102.97 64.9C107.9 64.5018 112.79 66.0609 116.58 69.24C120.358 72.3632 122.816 76.8 123.46 81.66C123.84 78.8295 124.839 76.1174 126.385 73.7168C127.932 71.3161 129.989 69.2857 132.41 67.77C135.799 65.5972 139.805 64.5895 143.82 64.9C143.87 64.96 143.94 65.01 143.94 65.06C143.84 69.2 144.49 73.4 143.2 77.46C142.76 78.84 142.09 80.15 141.54 81.46C141.393 81.8526 141.127 82.1893 140.779 82.4226C140.431 82.6558 140.019 82.7738 139.6 82.76C135.439 83.1556 131.46 84.6614 128.08 87.12C126.44 88.31 124.97 89.76 123.42 91.12C122.79 90.51 122.1 89.83 121.42 89.17C117.574 85.5727 112.658 83.3321 107.42 82.79C107 82.7855 106.59 82.6631 106.236 82.4368C105.883 82.2105 105.6 81.8893 105.42 81.51C103.863 78.7161 103.028 75.578 102.99 72.38L102.97 64.9Z"
          fill="black"
        />
        <path
          d="M76.53 105.04C78.17 93.24 89.03 87.47 97.05 88.38C97.05 89.66 97.05 90.95 97.05 92.24C97.05 95.12 97.2701 98.04 96.3 100.84C95.8 102.27 95.12 103.64 94.54 105.05C94.4045 105.391 94.1703 105.683 93.8674 105.89C93.5645 106.097 93.2067 106.208 92.84 106.21C86.717 106.802 81.0459 109.693 76.97 114.3C76.7885 114.531 76.5726 114.734 76.33 114.9C76.2131 114.598 76.0586 114.313 75.8701 114.05C71.8532 109.615 66.3336 106.829 60.38 106.23C59.984 106.217 59.5991 106.096 59.2675 105.879C58.9359 105.662 58.6705 105.358 58.5001 105C56.914 102.188 56.0675 99.0187 56.04 95.79C56.04 93.32 56.04 90.86 56.04 88.41C64.62 87.44 74.88 93.61 76.53 105.04Z"
          fill="black"
        />
        <path
          d="M102.97 88.4C111.65 87.4 121.9 93.75 123.49 105.08C124.13 100.212 126.597 95.7684 130.39 92.65C134.171 89.4869 139.046 87.9388 143.96 88.34C143.91 93.97 144.66 99.64 141.62 104.82C141.431 105.219 141.14 105.562 140.777 105.813C140.413 106.064 139.99 106.215 139.55 106.25C133.668 106.848 128.211 109.588 124.22 113.95C124.04 114.14 123.87 114.34 123.69 114.52C123.559 114.613 123.422 114.697 123.28 114.77C123.095 114.462 122.888 114.167 122.66 113.89C118.673 109.568 113.248 106.848 107.4 106.24C106.977 106.221 106.568 106.087 106.215 105.853C105.863 105.618 105.581 105.292 105.4 104.91C103.839 102.079 103.014 98.9023 103 95.67C102.97 93.27 102.97 90.86 102.97 88.4Z"
          fill="black"
        />
        <path
          d="M76.5498 67.88C74.64 65.7143 72.3906 63.8736 69.8898 62.43C69.7768 62.336 69.6886 62.2158 69.6328 62.0798C69.577 61.9438 69.5554 61.7963 69.5698 61.65C70.4858 57.0741 72.9596 52.9571 76.5698 50C80.1782 52.9671 82.6509 57.0896 83.5698 61.67C83.587 61.8027 83.5735 61.9376 83.5301 62.0642C83.4868 62.1909 83.4148 62.3057 83.3198 62.4C80.7732 63.8363 78.485 65.6885 76.5498 67.88Z"
          fill="black"
        />
        <path
          d="M123.25 67.69C121.406 65.6357 119.254 63.8813 116.87 62.49C116.733 62.3825 116.625 62.2436 116.553 62.0852C116.482 61.9268 116.45 61.7535 116.46 61.58C117.392 57.0328 119.865 52.9463 123.46 50.01C126.624 52.5957 128.926 56.0838 130.06 60.01C130.68 62.01 130.59 62.01 128.83 63.26C127.07 64.51 125.15 66.17 123.25 67.69Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_112">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { WheatIcon };
