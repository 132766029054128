import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import * as React from "react";

function MakiraIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="1606"
      height="500"
      viewBox="0 0 1606 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{width:"50px",marginLeft:"-9px",marginRight:"7px"}}
    >
      <g clip-path="url(#clip0_926_816)">
        <path
          d="M66.376 323.159H0V0H75.0969L171.512 182.251L268.411 0H343.508V323.159H277.132V121.609L171.512 295.3L66.376 121.609V323.159Z"
          fill="#2D2E2F"
        />
        <path
          d="M579.458 98.8373H640.989V323.159H579.458V292.151C558.624 318.799 534.399 330.911 501.454 330.911C438.469 330.911 393.411 281.977 393.411 213.663C393.411 145.349 440.892 91.0853 506.299 91.0853C536.822 91.0853 557.655 101.744 579.458 127.423V98.8373ZM459.303 212.694C459.303 251.938 484.981 281.492 518.411 281.492C551.841 281.492 580.427 249.516 580.427 210.756C580.427 171.996 555.233 142.926 518.896 142.926C482.558 142.926 459.303 171.512 459.303 212.694Z"
          fill="#2D2E2F"
        />
        <path
          d="M771.317 323.159H704.941V0H771.317V140.504L862.887 0H939.922L835.271 155.523L939.922 323.159H861.918L771.317 179.264V323.159Z"
          fill="#2D2E2F"
        />
        <path
          d="M1038.27 35.3682C1038.27 54.2636 1023.74 68.7984 1004.36 68.7984C984.98 68.7984 969.961 54.2636 969.961 34.8837C969.961 15.5039 984.496 0.484497 1004.36 0.484497C1024.22 0.484497 1038.27 15.0194 1038.27 35.3682ZM1034.88 323.159H973.352V98.8372H1034.88V323.159Z"
          fill="#2D2E2F"
        />
        <path
          d="M1165.7 323.159H1099.32V0H1199.13C1279.07 0 1322.19 35.3682 1322.19 101.744C1322.19 152.616 1295.54 186.531 1245.64 199.128L1334.79 323.159H1254.36L1165.7 194.767V323.159ZM1195.74 156.008C1233.53 156.008 1254.84 137.597 1254.84 105.136C1254.84 72.6744 1234.5 54.7481 1201.55 54.7481H1165.7V156.008H1195.74Z"
          fill="#2D2E2F"
        />
        <path
          d="M1544.09 98.8373H1605.62V323.159H1544.09V292.151C1523.26 318.799 1499.03 330.911 1466.09 330.911C1403.1 330.911 1358.04 281.977 1358.04 213.663C1358.04 145.349 1405.52 91.0853 1470.93 91.0853C1501.45 91.0853 1522.29 101.744 1544.09 127.423V98.8373ZM1423.93 212.694C1423.93 251.938 1449.61 281.492 1483.04 281.492C1516.47 281.492 1545.06 249.516 1545.06 210.756C1545.06 171.996 1519.86 142.926 1483.53 142.926C1447.19 142.926 1423.93 171.512 1423.93 212.694Z"
          fill="#2D2E2F"
        />
        <path
          d="M344.719 498.708H334.545V423.288H356.509C373.628 423.288 384.044 431.525 384.044 445.252C384.044 453.731 379.926 460.514 372.416 464.228C368.54 466.166 364.907 466.974 357.558 467.377L388.809 498.708H375.889L344.639 467.054V498.708H344.719ZM354.894 460.594C367.087 460.594 373.708 455.426 373.708 445.898C373.708 436.37 367.652 431.444 356.589 431.444H344.719V460.594H354.894Z"
          fill="#2D2E2F"
        />
        <path
          d="M465.117 434.916C471.657 442.022 474.564 450.42 474.564 461.725C474.564 473.03 469.719 484.012 460.918 491.037C453.65 496.932 444.526 500.081 435.159 500.081C423.208 500.081 411.499 494.751 403.989 485.95C398.418 479.409 395.511 470.93 395.511 461.402C395.511 438.873 412.549 421.915 435.401 421.915C446.625 421.915 457.365 426.68 465.117 434.916ZM405.927 461.079C405.927 478.601 418.282 491.36 434.916 491.36C442.991 491.36 450.986 488.049 456.396 482.316C461.079 477.471 464.148 469.315 464.148 461.644C464.148 451.55 461.241 444.202 454.942 438.55C449.613 433.705 442.265 430.717 435.159 430.717C418.363 430.717 405.927 443.637 405.927 460.998V461.079Z"
          fill="#2D2E2F"
        />
        <path
          d="M528.344 490.068V498.627H489.826V423.207H500.001V490.068H528.424H528.344Z"
          fill="#2D2E2F"
        />
        <path
          d="M579.861 490.068V498.627H541.344V423.207H551.518V490.068H579.942H579.861Z"
          fill="#2D2E2F"
        />
        <path
          d="M597.142 478.44C597.626 486.999 602.552 491.521 611.354 491.521C620.155 491.521 626.777 486.353 626.777 478.763C626.777 472.222 623.143 469.154 610.465 465.197C600.937 462.129 597.788 460.675 594.154 457.607C590.036 454.134 587.856 449.128 587.856 443.395C587.856 430.636 597.626 421.915 611.919 421.915C626.212 421.915 635.498 430.394 635.821 443.556H625.646C625.566 439.68 625 437.904 623.708 435.724C621.447 432.413 616.925 430.394 611.757 430.394C603.763 430.394 598.111 435.481 598.111 442.749C598.111 446.382 599.807 449.532 602.713 451.47C605.217 453.165 607.397 454.054 615.633 456.799C624.193 459.625 628.311 461.644 631.541 464.955C635.013 468.427 636.951 473.191 636.951 478.278C636.951 491.279 626.696 500.081 611.596 500.081C601.341 500.081 594.316 496.689 590.117 489.906C588.179 486.838 587.371 483.689 587.371 480.055V478.44H597.061H597.142Z"
          fill="#2D2E2F"
        />
        <path
          d="M696.706 481.266L689.358 498.708H678.86L709.141 423.288H721.254L750.889 498.708H739.503L732.882 481.266H696.787H696.706ZM714.955 435.239L699.694 473.11H729.652L714.955 435.239Z"
          fill="#2D2E2F"
        />
        <path
          d="M771.56 498.708H761.386V423.288H770.672L813.631 480.055V423.288H823.805V498.708H814.842L771.479 441.053V498.708H771.56Z"
          fill="#2D2E2F"
        />
        <path
          d="M843.831 423.207H865.23C889.535 423.207 904.151 437.016 904.151 459.706C904.151 472.465 899.71 483.043 891.473 489.906C884.448 495.801 875.162 498.627 863.453 498.627H843.912V423.207H843.831ZM854.005 490.472H857.639C871.286 490.472 877.1 489.099 882.914 484.819C890.181 479.409 893.815 471.011 893.815 459.787C893.815 441.537 883.398 431.202 864.987 431.202H854.086V490.472H854.005Z"
          fill="#2D2E2F"
        />
        <path
          d="M978.278 423.207C994.671 423.207 1004.04 430.475 1004.04 443.233C1004.04 448.078 1002.5 452.358 999.435 455.588C997.497 457.768 995.559 458.899 991.198 460.433C995.64 461.321 997.82 462.129 1000.4 463.905C1004.76 467.216 1007.03 472.303 1007.03 478.359C1007.03 485.384 1003.8 491.441 998.304 494.751C993.621 497.578 988.372 498.627 978.278 498.627H955.588V423.207H978.278ZM965.682 457.122H975.937C982.316 457.041 986.192 456.234 989.099 454.054C992.248 451.712 994.025 447.836 994.025 443.637C994.025 438.792 991.521 434.512 987.645 432.817C984.738 431.444 981.347 431.04 973.999 431.04H965.682V457.203V457.122ZM965.682 490.956H975.856C984.335 490.956 987.565 490.391 991.118 488.453C994.994 486.434 996.932 482.8 996.932 477.794C996.932 468.75 991.279 464.793 978.44 464.793H965.762V490.956H965.682Z"
          fill="#2D2E2F"
        />
        <path
          d="M1087.13 434.916C1093.67 442.022 1096.58 450.42 1096.58 461.725C1096.58 473.03 1091.73 484.012 1082.93 491.037C1075.66 496.932 1066.54 500.081 1057.17 500.081C1045.22 500.081 1033.51 494.751 1026 485.95C1020.43 479.409 1017.52 470.93 1017.52 461.402C1017.52 438.873 1034.56 421.915 1057.41 421.915C1068.64 421.915 1079.38 426.68 1087.13 434.916ZM1027.94 461.079C1027.94 478.601 1040.29 491.36 1056.93 491.36C1065 491.36 1073 488.049 1078.41 482.316C1083.09 477.471 1086.16 469.315 1086.16 461.644C1086.16 451.55 1083.25 444.202 1076.95 438.55C1071.62 433.705 1064.28 430.717 1057.17 430.717C1040.37 430.717 1027.94 443.637 1027.94 460.998V461.079Z"
          fill="#2D2E2F"
        />
        <path
          d="M1193.48 423.207H1203.97L1179.35 498.627H1170.38L1152.62 439.357L1134.77 498.627H1125.81L1101.18 423.207H1111.92L1130.25 481.024L1148.1 423.207H1157.62L1174.9 481.266L1193.4 423.207H1193.48Z"
          fill="#2D2E2F"
        />
        <path
          d="M1253.07 490.068V498.627H1214.55V423.207H1224.73V490.068H1253.15H1253.07Z"
          fill="#2D2E2F"
        />
        <path
          d="M1270.27 478.44C1270.75 486.999 1275.68 491.521 1284.48 491.521C1293.28 491.521 1299.9 486.353 1299.9 478.763C1299.9 472.222 1296.27 469.154 1283.59 465.197C1274.06 462.129 1270.91 460.675 1267.28 457.607C1263.16 454.134 1260.98 449.128 1260.98 443.395C1260.98 430.636 1270.75 421.915 1285.05 421.915C1299.34 421.915 1308.62 430.394 1308.95 443.556H1298.77C1298.69 439.68 1298.13 437.904 1296.84 435.724C1294.57 432.413 1290.05 430.394 1284.88 430.394C1276.89 430.394 1271.24 435.481 1271.24 442.749C1271.24 446.382 1272.93 449.532 1275.84 451.47C1278.34 453.165 1280.52 454.054 1288.76 456.799C1297.32 459.625 1301.44 461.644 1304.67 464.955C1308.14 468.427 1310.08 473.191 1310.08 478.278C1310.08 491.279 1299.82 500.081 1284.72 500.081C1274.47 500.081 1267.44 496.689 1263.24 489.906C1261.31 486.838 1260.5 483.689 1260.5 480.055V478.44H1270.19H1270.27Z"
          fill="#2D2E2F"
        />
        <path
          d="M640.988 66.376V0L395.107 0V66.376L640.988 66.376Z"
          fill="#2D2E2F"
        />
        <path
          d="M1605.62 66.376V0L1359.74 0V66.376L1605.62 66.376Z"
          fill="#2D2E2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_926_816">
          <rect width="1605.62" height="500" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { MakiraIcon };
