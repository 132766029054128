import { useState } from "react";
import { useAuthProvider } from "../../core/authContext";
import {
  useGetHotelDineInQuery,
  useDeleteDiningMutation,
  Dining,
} from "../../generated/graphql";

import swal from "sweetalert";

const useDining = () => {
  const [editData, setEditData] = useState<Dining | null>(null);

  const [open, setOpen] = useState(false);

  const handleClickEditOpen = (data: Dining | null) => {
    setEditData(data);
    setOpen(true);
  };

  const handleEditClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const [deleteDining] =
    useDeleteDiningMutation();

  const { getUser } = useAuthProvider();
  const property = getUser();

  //Get All Dining Services
  const {
    data: diningData,
    loading: loadingDining,
    refetch,
  } = useGetHotelDineInQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: property?.id,
    },
  });



  //delete
  const handleDelete = async (id: string) => {
    try {
      await deleteDining({
        variables: {
          id: id,
          propertyID: property.id,
        },
      });

      swal({
        text: "Service Deleted Successfully",
        icon: "success",
      });
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  return {
    diningData: diningData?.getMeta?.dining,
    loadingDining,
    handleClickEditOpen,
    handleEditClose,
    open,
    editData,
    handleDelete,
    propertyId: property.id,
    refetch,
    setOpen,
  };
};

export default useDining;
