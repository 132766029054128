import { Close, Delete, EditOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Input,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import * as React from "react";
import NoteViewer from "../../components/Editor";
import { LexicalEditor } from "lexical";
import { useParams } from "react-router-dom";
import useEditServices from "./edit-service-hook";
import { FrontDeskIcon } from "../../components/icons/FrontDeskIcon";
import { HousekeepingIcon } from "../../components/icons/HousekeepingIcon";
import { EngineeringIcon } from "../../components/icons/EngineeringIcon";
import { ConciergeIcon } from "../../components/icons/ConciergeIcon";

const EditService = () => {
    const {serviceID} = useParams();
    const {
        propertyId,
        editData,
        setEditData,
        onInitialEditorState,
        onFormattingChange,
        handleImage,
        loadingImage,
        image,
        setImage,
        onSubmit,
        showForm,
        setShowForm,
        handleSSEdit,
        ssEditData,
        setSSEditData,
        handleAddOrUpdate,
        setIndex,
        index,
        handleSSDelete
      } = useEditServices(serviceID);

      const serviceIcons = [
        {
          name: "SpaIcon",
          icon: <FrontDeskIcon />,
        },
        {
          name: "ActivitiesIcon",
          icon: <EngineeringIcon />,
        },
        {
          name: "HousekeepingIcon",
          icon: <HousekeepingIcon />,
        },
        {
          name: "SwimmingPoolIcon",
          icon: <ConciergeIcon />,
        },
      ];

    // if (!editData?.id) {
    //     return <Loader />;
    // }
  return (
    <>
      <Grid container direction={'column'} spacing={2} mt={2}>

        <Grid item md={7}>
          <TextField
            fullWidth
            label="Enter Service Name"
            value={editData?.name || ""}
            onChange={(e)=>setEditData({...editData,name:e.target.value,property_id:propertyId})}
          />
        </Grid>
        <Grid item md={7}>
        <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Icon
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Category Icon"
                  defaultValue={editData?.icon || ""}
                  onChange={(e)=>setEditData({...editData,icon:e.target.value || "",name:editData?.name || "",property_id:propertyId})}
                >
                  {serviceIcons?.map((ic, index) => (
                    <MenuItem key={index} value={ic.name}>
                      <ListItemIcon>{ic.icon}</ListItemIcon>
                      {ic.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </Grid>

        <Grid item>
          <Typography color="text.secondary">Sub Services</Typography>
        </Grid>

        <Grid item>
          <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Image</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceID && !editData?.sub_services && <CircularProgress/>}
              {editData?.sub_services && editData?.sub_services.map((row, index) => (
                <TableRow key={row?.name}>
                  <TableCell>
                    {row?.name}
                  </TableCell>
                  <TableCell>
                  {
                    row?.desc?.toString().startsWith("<") ? (
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{ __html: row?.desc?.toString() || '' }}
                      ></div>
                    ) : (
                      <Typography sx={{ color: 'text.secondary' }}>
                        {row?.desc}
                      </Typography>
                    )
                  }
                  </TableCell>
                  <TableCell>
                    <Box>
                      <img
                          alt="Service"
                          width={"100px"}
                          src={row?.img || ""}
                        />
                    </Box>    
                  </TableCell>
                  <TableCell>
                    <Box
                    component={Button}
                    bgcolor={"#fff"}
                    onClick={() => handleSSEdit(index)}
                    color={"#000"}
                  >
                    <EditOutlined />
                  </Box>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Delete" placement="top" arrow>
                      <Box
                        component={Button}
                        bgcolor={"#fff"}
                        onClick={() => handleSSDelete(index)}
                        color={"#E6170A"}
                      >
                        <Delete />
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </Paper>
        </Grid>

        <Grid item>
          <Button 
            onClick={()=>{
              if(showForm===true) {
                setSSEditData(null);
                setIndex(null);
              };
              setShowForm(!showForm)
              }} 
            variant="outlined">
            Add
          </Button>
        </Grid>
      </Grid>

        {showForm && 

          <Box sx={{ width: '70%' }}>
            <Stack spacing={2} mt={2}>

                <TextField
                  fullWidth
                  label="Enter Sub Service Name"
                  value={ssEditData?.name || ""}
                  onChange={(e)=>setSSEditData({...ssEditData,name:e.target.value})}
                />

                <NoteViewer 
                  onChange={
                    (editorState: string, editorInstance?: LexicalEditor) => {
                      onFormattingChange(editorState,editorInstance);
                    }}
                  initialEditorState={
                    (editor: LexicalEditor) => {
                      onInitialEditorState(editor);
                    }
                  }
                />
              
                <TextField
                  label="Enter Service ID"
                  type="number"
                  value={ssEditData?.service_id}
                  onChange={(e)=>setSSEditData({...ssEditData,service_id:parseInt(e.target.value),name:ssEditData?.name || ""})}
                />
              
                <TextField
                  label="Enter Booking URL"
                  value={ssEditData?.booking_url}
                  onChange={(e)=>setSSEditData({...ssEditData,booking_url:e.target.value,name:ssEditData?.name || ""})}
                />
              <Box>
                {loadingImage ? (
                  <Box
                    sx={{
                      height: 100,
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "60px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  (ssEditData?.img || image) && (
                    <Box>
                      <img
                        alt="Service"
                        width={"250px"}
                        src={image ? image : ssEditData?.img}
                      />

                      {(ssEditData?.img || image) && (
                        <Box
                          component={IconButton}
                          onClick={() => setImage(null)}
                        >
                          <Close />
                        </Box>
                      )}
                    </Box>
                  )
                )}
              </Box>
              <Button variant="outlined" sx={{width:"10%"}}>
                <label>
                  {index!==null ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
              <Button 
                variant="outlined" 
                sx={{width:"15%"}}
                onClick={handleAddOrUpdate}
              >
                {index!==null ? "Update Sub Service" : "Add Sub Service"}
              </Button>
            </Stack>
          </Box>
        }

          <Stack direction="row" spacing={2} mt={2}>
            <LoadingButton
              onClick={onSubmit}
              variant="contained"
            //   disabled={!isDirty && isDisable}
              loading={false}
            >
              {editData ? "Update Data" : "Submit Data"}
            </LoadingButton>
          </Stack>
          </>
  );
};

export default EditService;
