import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import Loader from "../../components/Loader";
import NoteViewer from "../../components/Editor";
import { LexicalEditor } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";

import useHotelInformation from "./hotel-info-hook";
import { Controller } from "react-hook-form";

const HotelInformation = () => {
  const {
    propertyRegister,
    handleSubmitProperty,
    isDirtyProperty,
    onSubmitProperty,
    register,
    handleSubmit,
    onSubmitMetaData,
    handleImage,
    setPropertyValue,
    setMetaValue,
    propertyWatch,
    loading,
    metaWatch,
    handlePropertyImage,
    // isDirty,
    // isDisable,
    isDisableProperty,
    font,
    setFont,
    // getMetaValue,
    hotelDetails,
    CreateCheckWithComboMeals,
    GetDiscounts,
    GetServiceCharges,
    control,
  } = useHotelInformation();
  const [showAPI, setShowAPI] = React.useState(false);

  const onInitialEditorState = useCallback(
    (editor: LexicalEditor, fieldKey: string) => {
      let htmlString = "";
      switch (fieldKey) {
        case "address":
          htmlString = hotelDetails?.dir_con.address || "";
          break;
        case "feedback_desc":
          htmlString = hotelDetails?.feedback_desc || "";
          break;
        case "directory_description":
          htmlString = hotelDetails?.directory_description || "";
          break;
        case "promotion_desc":
          htmlString = hotelDetails?.promotion_desc || "";
          break;
        case "description":
          htmlString = hotelDetails?.about.desc || "";
          break;
        default:
          break;
      }
      if (!htmlString.startsWith("<")) {
        htmlString = `<p>${htmlString}</p>`;
      }
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlString, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().select();
      $insertNodes(nodes);
    },
    [hotelDetails]
  );

  const onFormattingChange = useCallback(
    (fieldKey: string, editorInstance?: LexicalEditor) => {
      if (editorInstance) {
        editorInstance.update(() => {
          const htmlString = $generateHtmlFromNodes(editorInstance, null);
          switch (fieldKey) {
            case "address":
              setMetaValue("address", htmlString);
              break;
            case "feedback_desc":
              setMetaValue("feedback_desc", htmlString);
              break;
            case "directory_description":
              setMetaValue("directory_description", htmlString);
              break;
            case "promotion_desc":
              setMetaValue("promotion_desc", htmlString);
              break;
            case "description":
              setMetaValue("description", htmlString);
              break;
            default:
              break;
          }
        });
      }
    },
    [setMetaValue]
  );

  const Font = [
    { fontName: "Roboto", fontstyle: "Roboto" },
    { fontName: "Open Sans", fontstyle: "Open Sans" },
    { fontName: "Montserrat", fontstyle: "Montserrat" },
    { fontName: "Poppins", fontstyle: "Poppins" },
    { fontName: "Source Sans Pro", fontstyle: "Source Sans Pro" },
    { fontName: "Raleway", fontstyle: "Raleway" },
    { fontName: "Ubuntu", fontstyle: "Ubuntu" },
    { fontName: "Work Sans", fontstyle: "Work Sans" },
    { fontName: "Lora", fontstyle: "Lora" },
    { fontName: "Noto Serif", fontstyle: "Noto Serif" },
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box component={"form"} onSubmit={handleSubmitProperty(onSubmitProperty)}>
        <Typography variant="h4" align="center" fontWeight={600}>
          App Setting
        </Typography>
        <Grid container spacing={1} pt={2}>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12}>
                <TextField
                  {...propertyRegister("api_key")}
                  fullWidth
                  disabled={showAPI ? false : true}
                  type={showAPI ? "text" : "password"}
                  InputLabelProps={{ shrink: true }}
                  label="API Key"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowAPI((show) => !show)}
                          edge="end"
                        >
                          {<EditIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Domain"
                  {...propertyRegister("domain", {
                    maxLength: 30,
                    required: "Domain is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="API URL"
                  {...propertyRegister("api_url", {
                    required: "Api url is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Chat API URL"
                  {...propertyRegister("chat_url", {
                    required: "chat api url is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Name"
                  {...propertyRegister("name", {
                    maxLength: 30,
                    required: "Hotel name is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Currency"
                  {...propertyRegister("currency", {
                    required: "Currency is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Color"
                  {...propertyRegister("primary_color", {
                    maxLength: 30,
                    required: "primary color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Light"
                  {...propertyRegister("primary_light", {
                    maxLength: 30,
                    required: "primary light is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_light")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Background Color"
                  {...propertyRegister("background_color", {
                    maxLength: 30,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("background_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Tile Color"
                  {...propertyRegister("tile_color", {
                    maxLength: 30,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("tile_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                {/* <div> */}
                <FormControl fullWidth>
                  <InputLabel
                    id="font-select-label"
                    style={{ backgroundColor: "white", padding: "2px 5px" }}
                  >
                    Select a Font
                  </InputLabel>
                  <Select
                    // fullWidth
                    labelId="font-select-label"
                    id="font-select"
                    {...propertyRegister("font", {})}
                    value={font}
                    onChange={(event) => setFont(event.target.value)}
                  >
                    {Font.map((ic, index) => (
                      <MenuItem
                        style={{ fontFamily: ic.fontstyle }}
                        key={index}
                        value={ic.fontName}
                      >
                        {ic.fontName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel
                    id="dining-select-label"
                    style={{ backgroundColor: "white", padding: "2px 5px" }}
                  >
                    Show Dining By
                  </InputLabel>
                  <Select
                    // fullWidth
                    labelId="dining-select-label"
                    id="dining-select"
                    {...propertyRegister("by_dining", {})}
                    value={`${propertyWatch("by_dining")}`}
                  >
                    <MenuItem key="Category" value="Category">
                      Category
                    </MenuItem>
                    <MenuItem key="Section" value="Section">
                      Section
                    </MenuItem>
                  </Select>
                </FormControl>
                {/* </div> */}
              </Grid>
              <Grid item md={6} sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyWatch("show_talk_to_us") || false}
                    />
                  }
                  {...propertyRegister("show_talk_to_us")}
                  label="Show Talk To Us"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyWatch("show_main_category") || false}
                    />
                  }
                  {...propertyRegister("show_main_category")}
                  label="Allow to Select Main Category"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12} mb={2}>
                <Typography color="text.secondary" gutterBottom>
                  Select Logo
                </Typography>
                <Box>
                  <img
                    alt="logo"
                    width={"250px"}
                    height={"160px"}
                    src={propertyWatch("img")}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    Update Logo
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handlePropertyImage(e, setPropertyValue, "img")
                      }
                    />
                  </label>
                </Button>
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Text Color"
                  {...propertyRegister("primary_text_color", {
                    maxLength: 30,
                    required: "Primary text color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_text_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Secondary Text Color"
                  {...propertyRegister("secondary_text_color", {
                    maxLength: 30,
                    required: "Secondary text color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("secondary_text_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/*has_simphony_pos*/}
              {/*simphony_pos_url*/}
              {/*direct_simphony_pos_check*/}
              {/*pos_loc_ref*/}
              {/*pos_rvc_ref*/}
            </Grid>
            <Grid container spacing={1} m={1}>
              <Grid item md={6}>
                <FormControlLabel
                    control={
                      <Checkbox checked={Boolean(propertyWatch("has_simphony_pos")) || false} />
                    }
                    {...propertyRegister("has_simphony_pos")}
                    label="Has Simphony POS"
                />
              </Grid>
            </Grid>
            {
              propertyWatch("has_simphony_pos") &&
                <Grid container spacing={1} m={1}>
                  <Grid item md={6}>
                    <FormControlLabel
                        control={<Checkbox checked={propertyWatch("direct_simphony_pos_check") || false} />}
                        {...propertyRegister("direct_simphony_pos_check")}
                        label="Create Check on POS after guest checkout only"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="POS API URL"
                        {...propertyRegister("simphony_pos_url")}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Location Code"
                        {...propertyRegister("pos_loc_ref")}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Revenue Center Code"
                        {...propertyRegister("pos_rvc_ref")}
                    />
                  </Grid>
                </Grid>
            }
            {
              // Temporary Code to create fake check and get disounts/service charges details
                propertyWatch("has_simphony_pos") && propertyWatch("domain") === "guestdev" &&
                <Grid container spacing={1} m={1}>
                  <Grid item md={4}>
                    <Button
                        variant="contained"
                        onClick={() => CreateCheckWithComboMeals()}
                    >
                      Create Check
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                        variant="contained"
                        onClick={() => GetDiscounts()}
                    >
                      Discounts
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                        variant="contained"
                        onClick={() => GetServiceCharges()}
                    >
                      Service Charges
                    </Button>
                  </Grid>
                </Grid>
            }
          </Grid>
          <Grid container spacing={2} m={2}>
            <Stack direction="row" spacing={2} margin="auto">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!isDirtyProperty && isDisableProperty}
                loading={false}
              >
                Update Property
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box component={"form"} onSubmit={handleSubmit(onSubmitMetaData)}>
          <Typography variant="h4" align="center" fontWeight={600}>
            Hotel Information
          </Typography>
          <Grid container spacing={1} pt={2}>
            <Grid item md={4}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Hotel Name"
                {...register("hotel_name", {
                  required: "Hotel Name is required!",
                })}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                label="Email"
                {...register("email")}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Phone"
                {...register("phone")}
              />
            </Grid>
          </Grid>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Address
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("address", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "address");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Directory Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("directory_description", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "directory_description");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Add Property Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("description", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "description");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Promotion Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("promotion_desc", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "promotion_desc");
              }}
            />
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography color="text.primary" gutterBottom>
              Feedback Description
            </Typography>
            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange("feedback_desc", editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor, "feedback_desc");
              }}
            />
          </Stack>
          <Grid container spacing={1} pt={2}>
            <Grid container spacing={1} m={3} mt={1}>
              {/* About Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select About Image
                </Typography>
                <Box>
                  <img
                    alt="about img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("aboutImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update About
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "aboutImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Controller
                      name={"about"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="about"
                />
              </Grid>
              {/* Select Direction Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Direction Image
                </Typography>
                <Box>
                  <img
                    alt="direction img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("directionImg") || "assets/defaultImg.jpg"}
                  />
                </Box>

                <Button variant="outlined" style={{ marginLeft: "-10px" }}>
                  <label>
                    Update Direction
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "directionImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  control={
                    <Controller
                      name={"directory"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="directory"
                />
              </Grid>

              {/* Select Direction Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Dining Image
                </Typography>
                <Box>
                  <img
                    alt="dining img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("diningImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Dining
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "diningImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "4px" }}
                  control={
                    <Controller
                      name={"dining"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="dining"
                />
              </Grid>

              {/* Select Hotel Service Image */}
              <Grid item lg={2} md={4}>
                <Typography color="text.secondary" gutterBottom>
                  Select Service Image
                </Typography>
                <Box>
                  <img
                    alt="service img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("serviceImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Service
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "serviceImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  control={
                    <Controller
                      name={"hotel_services"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="services"
                />
              </Grid>

              <Grid item lg={2} md={4}>
                {/* <Grid item md={3} mb={2}> */}
                <Typography color="text.secondary" gutterBottom>
                  Select Rooms Image
                </Typography>
                <Box>
                  <img
                    alt="room img"
                    width={"150px"}
                    height={"100px"}
                    src={metaWatch("roomsImg") || "assets/defaultImg.jpg"}
                  />
                </Box>
                <Button variant="outlined">
                  <label>
                    update Room
                    <Input
                      type="file"
                      sx={{ display: "none", cursor: "pointer" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleImage(e, setMetaValue, "roomsImg")
                      }
                    />
                  </label>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: "5px" }}
                  control={
                    <Controller
                      name={"hotel_rooms"}
                      control={control}
                      render={({ field: props }) => (
                        <Switch
                          {...props}
                          checked={props.value || false}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label="rooms"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} m={2}>
              <Stack direction="row" spacing={2} margin="auto">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  // disabled={!isDirty && isDisable}
                  loading={false}
                >
                  Update Data
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HotelInformation;
